import axios from "axios";
import { toastHandler } from "@/api/utilities";

const doGetFeed = async () => {
    return toastHandler(axios.get("/feed/public"), false);
}

const doGetMyFeed = async () => {
    return toastHandler(axios.get("/feed"), false);
}

const doGetSortByTop = async () => {
    return toastHandler(axios.get("/articles/sort-by-top"), false);
}

const doPostIncrementShareCounter = async (submissionId) => {
    return axios.post("/articles/share/count-increment",{id:submissionId}
    ).then(response => {
        return response.data;
    }).catch(error => {
        console.error("There was an error!", error);
        return error;
    });
}

const doFinishOnboarding = async () => {
    return toastHandler(axios.put("/feed/onboarding/finish"), false);

}

const getOnboarding = async () => {
    return toastHandler(axios.get("/feed/onboarding"), false);
}

export {
    doGetFeed,
    doPostIncrementShareCounter,
    doGetSortByTop,
    doGetMyFeed,
    doFinishOnboarding,
    getOnboarding
}
