import axios from "axios";

const doGetUserSubscription = async () => {
  return axios.get("/subscription/user");
}

const doGetPaymentIntent = async () => {
  return axios.get("/subscription/payment-intent");
}

const doPostConfirmSubscription = async (payload) => {
  return axios.post("/subscription/confirm-payment", payload);
}

const doPostConfirmDowngradeSubscription = async (payload) => {
  return axios.post("/subscription/downgrade", payload);
}

const doGetSubscriptionChangePreview = async (payload) => {
  return axios.get("/subscription/change/preview", payload);
}

export {
  doGetUserSubscription,
  doGetPaymentIntent,
  doPostConfirmSubscription,
  doPostConfirmDowngradeSubscription,
  doGetSubscriptionChangePreview,
}
