import { doGetProfileDetails, doUpdateEmail, doGetUserPaymentMethod, doUpdateBillingAddress,
  doDeletePaymentMethod,  doPostPaymentMethod, doGetPaymentHistory, doGetPaymentReceiptPdf, doUpdateProfileNames } from "@/api/profile";
import { isSuccessfulResponse } from "@/api/utilities";
  
export default {
  namespaced: true,
  state: () => ({
    profile: null,
    paymentMethod: null,
  }),
  mutations: {
    setProfile(state, data) {
      state.profile = data;
    },
    setPaymentMethod(state, data) {
      state.paymentMethod = data;
    }
  },
  actions: {
    async getProfile(context) {
      try {
        const response = await doGetProfileDetails();
    
        if(isSuccessfulResponse(response)) {
          context.commit('setProfile', response.data);
        }
      } catch (error) {
        context.commit('setProfile', null);
      }
    },
    async updateEmail(context, payload) {
      let response = await doUpdateEmail(payload);

      if(isSuccessfulResponse(response)) {
        await context.dispatch('auth/getUser', null, { root: true });
      }

      return response;
    },
    async getUserPaymentMethod(context) {
      let response = await doGetUserPaymentMethod();

      if(isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', response.data);
        return response.data;
      }

      return response.error;
    },
    async updateBillingAddress(context, payload) {
      let response = await doUpdateBillingAddress(payload);

      if(isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async deletePaymentMethod(context) {
      let response = await doDeletePaymentMethod();

      if(isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', null);
        return response.data;
      }

      return response.error;
    },
    async addPaymentMethod(context, payload) {
      let response = await doPostPaymentMethod(payload);

      if(isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', response.data);
        return response.data;
      }

      return response.error;
    },
    async getPaymentHistory() {
      let response = await doGetPaymentHistory();

      if(isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getPaymentReceiptPdf(context, payload) {
      let response = await doGetPaymentReceiptPdf(payload);

      if(isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async updateProfileNames(context, payload) {
      let response = await doUpdateProfileNames(payload);

      if(isSuccessfulResponse(response)) {
        await context.dispatch('getProfile');
        return response;
      }

      return response.error;
    },
  },
  getters: {
    currentProfile: state => {
      return state.profile;
    },
    currentPaymentMethod: state => {
      return state.paymentMethod;
    }
  }
}
