import axios from "axios";
import { toastHandler } from "@/api/utilities";

const doGetUserKeywords = async () => {
  return axios.get("/user/keywords");
}

const doGetAllKeywords = async () => {
  return axios.get("/keywords");
}

const doGetTopKeywords = async () => {
  return axios.get("/keywords/top");
}

const doSubscribeKeywordsToUser = (payload) => {
  return toastHandler(axios.post("/user/keywords/subscribe", payload));
}

const doUnsubscribeKeywordsToUser = async (payload) => {
  return toastHandler(axios.post("/user/keywords/unsubscribe", payload));
}

export {
  doGetUserKeywords,
  doGetAllKeywords,
  doGetTopKeywords,
  doSubscribeKeywordsToUser,
  doUnsubscribeKeywordsToUser,
}
