<script>
export default {
  data: () => ({
    phone: {
      wellFormedPhoneNumber: phoneNumber => {
        return phoneNumber.replace(/-/g,'').replace(/\(/g,'').replace(/\)/g,'');
      },
    },
    number: {
      shrinkNumberAmount: number => {

        if(number >= 1000) {
          return ((number/1000).toFixed(1))+'K';
        }

        return number;
      }
    },
    parse: {
      richText(unformatedText) {

        let specialCharacters = unformatedText.replace(/START#SUP#START/g, "<sup>");
        specialCharacters = specialCharacters.replace(/END%SUP%END/g, "</sup>");

        specialCharacters = specialCharacters.replace(/START#SUB#START/g, "<sub>");
        specialCharacters = specialCharacters.replace(/END%SUB%END/g, "</sub>");

        specialCharacters = specialCharacters.replace(/START#I#START/g, "<i>");
        specialCharacters = specialCharacters.replace(/END%I%END/g, "</i> ");

        return `${specialCharacters}`;
      }
    },
    onboardingEnum: { PENDING: "pending", DONE: "done" },
  })
}
</script>
