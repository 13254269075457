<template>
  <div>
    <svg
      v-if="!checkScreenSize"
      width="141"
      height="24"
      viewBox="0 0 141 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.953 23.495C21.3647 23.5004 20.7955 23.2939 20.356 22.9157C19.6395 22.2412 18.9741 21.5176 18.3651 20.7506C16.6963 18.7606 14.6175 16.2891 12.1529 16.2865C9.70152 16.2865 7.59618 18.7683 5.90605 20.7635C5.29499 21.5248 4.62975 22.244 3.91516 22.9157C3.46696 23.2954 2.89051 23.5033 2.2947 23.5C1.69888 23.4966 1.12494 23.2824 0.681295 22.8977C-0.0772667 22.1588 -0.207686 21.1548 0.316653 20.1379C0.401825 19.9783 8.46919 4.78131 9.71483 2.3742C10.0502 1.72286 10.673 0.520596 12.1103 0.5H12.1449C13.5928 0.520596 14.213 1.72286 14.5403 2.3742C15.786 4.78131 23.856 19.9783 23.9359 20.1379C24.4682 21.1677 24.3324 22.1666 23.5765 22.8977C23.1335 23.2891 22.553 23.5027 21.953 23.495ZM12.1502 14.7418C15.3761 14.7418 17.721 17.5325 19.6028 19.7749C20.1614 20.4797 20.7697 21.1463 21.4233 21.7701C21.5701 21.8815 21.7501 21.9441 21.9366 21.9488C22.1231 21.9534 22.3062 21.8998 22.4587 21.7958C22.6343 21.6285 22.7994 21.3633 22.5225 20.8278C22.448 20.6837 14.3727 5.47898 13.1297 3.07445C12.6905 2.21458 12.4536 2.04467 12.1369 2.04467C11.8202 2.04467 11.5833 2.21458 11.1415 3.07445C9.89316 5.48928 1.82579 20.694 1.74062 20.8381C1.47446 21.3659 1.63415 21.6311 1.80982 21.7984C1.9628 21.9012 2.14567 21.9541 2.33186 21.9495C2.51806 21.9448 2.69788 21.8829 2.84519 21.7727C3.49877 21.151 4.10875 20.4879 4.67106 19.7878C6.56614 17.5506 8.92965 14.7676 12.1236 14.7444H12.1609L12.1502 14.7418Z"
        fill="url(#paint0_linear_3323_9012)"
      />
      <ellipse
        cx="12.1105"
        cy="21.2254"
        rx="2.26238"
        ry="2.18828"
        fill="url(#paint1_linear_3323_9012)"
      />
      <g clip-path="url(#clip0_3323_9012)">
        <g clip-path="url(#clip1_3323_9012)">
          <path
            d="M44.4491 16.9505C44.4491 17.0277 44.4225 17.0792 44.3692 17.1307C44.316 17.1822 44.2628 17.208 44.1829 17.208H43.2247C43.1449 17.208 43.0917 17.1822 43.0384 17.1307C42.9852 17.0792 42.9586 17.0277 42.9586 16.9505V14.6078C42.9586 14.5305 42.932 14.479 42.8787 14.4276C42.8255 14.3761 42.7723 14.3503 42.6924 14.3503H37.6087C37.5289 14.3503 37.4756 14.3246 37.4224 14.2731C37.3692 14.2216 37.3426 14.1701 37.3426 14.0929V13.1918C37.3426 13.1146 37.3692 13.0631 37.4224 13.0116C37.4756 12.9601 37.5289 12.9344 37.6087 12.9344H42.719C42.7989 12.9344 42.8521 12.9086 42.9054 12.8571C42.9586 12.8057 42.9852 12.7542 42.9852 12.6769V8.89249C42.9852 8.81526 42.9586 8.76377 42.9054 8.71228C42.8521 8.66079 42.7989 8.63505 42.719 8.63505H37.662C37.1563 8.63505 36.6772 8.73803 36.2247 8.91824C35.7988 9.09845 35.3996 9.3559 35.0536 9.69057C34.7076 10.0253 34.4414 10.4114 34.2551 10.8233C34.0688 11.261 33.9623 11.7244 33.9623 12.2135V16.9505C33.9623 17.0277 33.9357 17.0792 33.8825 17.1307C33.8292 17.1822 33.776 17.208 33.6961 17.208H32.738C32.6581 17.208 32.6049 17.1822 32.5517 17.1307C32.4984 17.0792 32.4718 17.0277 32.4718 16.9505V12.2135C32.4718 11.7759 32.525 11.3125 32.6581 10.9006C32.7912 10.4887 32.9509 10.0767 33.1904 9.71632C33.4034 9.3559 33.6961 8.99547 33.9889 8.71228C34.3083 8.40335 34.6543 8.1459 35.0536 7.9142C35.4528 7.6825 35.8521 7.52804 36.2779 7.39932C36.7304 7.24485 37.2095 7.19336 37.662 7.19336H44.1829C44.2628 7.19336 44.316 7.2191 44.3692 7.27059C44.4225 7.32208 44.4491 7.37357 44.4491 7.4508V16.9505Z"
            fill="#263684"
          />
          <path
            d="M59.4607 16.9505C59.4607 17.0277 59.4341 17.0792 59.3808 17.1307C59.3276 17.1822 59.2744 17.208 59.1945 17.208H52.7268C52.0348 17.208 51.3693 17.0792 50.7306 16.8218C50.145 16.5643 49.6393 16.2039 49.2134 15.7663C48.7876 15.3029 48.4416 14.7622 48.2286 14.1701C47.9891 13.5522 47.8826 12.8829 47.8826 12.2135C47.8826 11.5442 47.9891 10.9006 48.2286 10.2827C48.4416 9.69057 48.7876 9.14994 49.2134 8.68654C49.6393 8.22314 50.145 7.86272 50.7306 7.60527C51.3693 7.32208 52.0348 7.19336 52.7268 7.19336H59.1945C59.2744 7.19336 59.3276 7.2191 59.3808 7.27059C59.4341 7.32208 59.4607 7.37357 59.4607 7.4508V8.35186C59.4607 8.42909 59.4341 8.48058 59.3808 8.53207C59.3276 8.58356 59.2744 8.60931 59.1945 8.60931H52.7268C52.2477 8.60931 51.7686 8.71228 51.3161 8.89249C50.9169 9.07271 50.5442 9.33015 50.2781 9.66483C49.9853 9.99951 49.7724 10.3857 49.6127 10.7976C49.453 11.2352 49.3731 11.7244 49.3998 12.1878C49.3998 12.6512 49.4796 13.1403 49.6127 13.578C49.7724 13.9899 49.9853 14.3761 50.2781 14.7107C50.5709 15.0454 50.9169 15.3029 51.3161 15.4831C51.742 15.6633 52.2211 15.7663 52.7268 15.7663H59.1945C59.2744 15.7663 59.3276 15.792 59.3808 15.8435C59.4341 15.895 59.4607 15.9465 59.4607 16.0237V16.9505Z"
            fill="#263684"
          />
          <path
            d="M74.4988 16.9509C74.4988 17.0281 74.4722 17.0796 74.419 17.1311C74.3658 17.1826 74.3125 17.2083 74.2327 17.2083H73.3011C73.2213 17.2083 73.168 17.1826 73.1148 17.1311C73.0616 17.0796 73.0349 17.0281 73.0349 16.9509V14.6081C73.0349 14.5309 73.0083 14.4794 72.9551 14.4279C72.9019 14.3764 72.8486 14.3507 72.7688 14.3507H63.8524C63.7725 14.3507 63.7193 14.3249 63.666 14.2734C63.6128 14.222 63.5862 14.1705 63.5862 14.0932V13.1922C63.5862 13.1149 63.6128 13.0635 63.666 13.012C63.7193 12.9605 63.7725 12.9347 63.8524 12.9347H72.7422C72.822 12.9347 72.8753 12.909 72.9285 12.8575C72.9817 12.806 73.0083 12.7545 73.0083 12.6773V8.89285C73.0083 8.81561 72.9817 8.76413 72.9285 8.71264C72.8753 8.66115 72.822 8.6354 72.7422 8.6354H67.6851C67.206 8.6354 66.7003 8.73838 66.2478 8.91859C65.7953 9.0988 65.3961 9.35625 65.0767 9.69093C64.7307 10.0256 64.4645 10.4118 64.2782 10.8237C64.0919 11.2613 63.9854 11.7247 63.9854 12.2139V16.9509C63.9854 17.0281 63.9588 17.0796 63.9056 17.1311C63.8524 17.1826 63.7991 17.2083 63.7193 17.2083H62.7877C62.7079 17.2083 62.6546 17.1826 62.6014 17.1311C62.5482 17.0796 62.5215 17.0281 62.5215 16.9509V12.1881C62.5215 11.7505 62.5748 11.2871 62.7079 10.8494C62.9474 9.99986 63.3999 9.25327 64.0387 8.6354C64.6775 8.01754 65.4493 7.57988 66.3277 7.34818C66.7801 7.21946 67.2326 7.16797 67.7117 7.16797H74.2593C74.3391 7.16797 74.3924 7.19371 74.4456 7.2452C74.4988 7.29669 74.5255 7.34818 74.5255 7.42541L74.4988 16.9509Z"
            fill="#263684"
          />
          <path
            d="M90.5217 10.7461C90.5217 11.2352 90.4153 11.6986 90.2289 12.1363C89.8563 12.9859 89.1377 13.681 88.2593 14.0414C87.8069 14.2216 87.3012 14.3246 86.8221 14.3246H83.2821C83.2023 14.3246 83.149 14.2988 83.0958 14.2473C83.0426 14.1959 83.0159 14.1444 83.0159 14.0671V13.1918C83.0159 13.1146 83.0426 13.0631 83.0958 13.0116C83.149 12.9601 83.2023 12.9344 83.2821 12.9344H86.8221C87.1148 12.9344 87.4076 12.8829 87.7004 12.7799C88.2327 12.5482 88.6586 12.1363 88.8715 11.6214C88.978 11.364 89.0578 11.055 89.0578 10.7718C89.0578 10.4887 89.0046 10.2055 88.8715 9.92227C88.765 9.66483 88.6054 9.43313 88.3924 9.25292C88.1795 9.04696 87.9399 8.89249 87.6738 8.78952C87.4076 8.68654 87.1148 8.63505 86.8221 8.63505H80.3011C80.2212 8.63505 80.168 8.66079 80.1148 8.71228C80.0616 8.76377 80.0349 8.81526 80.0349 8.89249V16.9505C80.0349 17.0277 80.0083 17.0792 79.9551 17.1307C79.9019 17.1822 79.8486 17.208 79.7688 17.208H78.8372C78.7574 17.208 78.7041 17.1822 78.6509 17.1307C78.5977 17.0792 78.571 17.0277 78.571 16.9505V7.4508C78.571 7.37357 78.5977 7.32208 78.6509 7.27059C78.7041 7.2191 78.7574 7.19336 78.8372 7.19336H86.8487C87.3544 7.19336 87.8335 7.29634 88.286 7.47655C89.1643 7.83697 89.8829 8.53207 90.2556 9.38164C90.4419 9.8193 90.5483 10.2827 90.5217 10.7461Z"
            fill="#263684"
          />
          <path
            d="M104.336 16.9505C104.336 17.0277 104.309 17.0792 104.256 17.1307C104.202 17.1822 104.149 17.208 104.069 17.208H93.8487C93.7689 17.208 93.7157 17.1822 93.6624 17.1307C93.6092 17.0792 93.5826 17.0277 93.5826 16.9505V7.4508C93.5826 7.37357 93.6092 7.32208 93.6624 7.27059C93.7157 7.2191 93.7689 7.19336 93.8487 7.19336H104.069C104.149 7.19336 104.202 7.2191 104.256 7.27059C104.309 7.32208 104.336 7.37357 104.336 7.4508V8.35186C104.336 8.42909 104.309 8.48058 104.256 8.53207C104.202 8.58356 104.149 8.60931 104.069 8.60931H95.3392C95.2594 8.60931 95.2062 8.63505 95.1529 8.68654C95.0997 8.73803 95.0731 8.78952 95.0731 8.86675V15.5088C95.0731 15.5861 95.0997 15.6375 95.1529 15.689C95.2062 15.7405 95.2594 15.7663 95.3392 15.7663H104.069C104.149 15.7663 104.202 15.792 104.256 15.8435C104.309 15.895 104.336 15.9465 104.336 16.0237V16.9505ZM103.085 12.6512C103.085 12.7284 103.058 12.7799 103.005 12.8314C102.951 12.8829 102.898 12.9086 102.818 12.9086H94.6738C94.594 12.9086 94.5408 12.8829 94.4875 12.8314C94.4343 12.7799 94.4077 12.7284 94.4077 12.6512V11.7501C94.4077 11.6729 94.4343 11.6214 94.4875 11.5699C94.5408 11.5184 94.594 11.4927 94.6738 11.4927H102.792C102.872 11.4927 102.925 11.5184 102.978 11.5699C103.031 11.6214 103.058 11.6729 103.058 11.7501L103.085 12.6512Z"
            fill="#263684"
          />
          <path
            d="M119.959 12.1878C119.959 12.8571 119.853 13.5265 119.613 14.1444C119.4 14.7365 119.054 15.2771 118.628 15.7405C118.176 16.2039 117.644 16.5901 117.032 16.8218C116.419 17.0792 115.781 17.208 115.115 17.1822H107.849C107.769 17.1822 107.716 17.1565 107.663 17.105C107.609 17.0535 107.583 17.002 107.583 16.9248V16.0237C107.583 15.9465 107.609 15.895 107.663 15.8435C107.716 15.792 107.769 15.7663 107.849 15.7663H115.142C115.621 15.7663 116.1 15.689 116.526 15.4831C116.925 15.3029 117.298 15.0454 117.564 14.7107C117.857 14.3761 118.07 13.9899 118.229 13.578C118.389 13.1403 118.469 12.6512 118.469 12.1878C118.469 11.7244 118.389 11.2352 118.229 10.7976C118.096 10.3857 117.857 9.99951 117.564 9.66483C117.271 9.33015 116.925 9.07271 116.526 8.89249C116.1 8.68654 115.621 8.60931 115.142 8.60931H109.339C109.26 8.60931 109.206 8.63505 109.153 8.68654C109.1 8.73803 109.073 8.78952 109.073 8.86675V13.1661C109.073 13.2433 109.047 13.2948 108.993 13.3463C108.94 13.3978 108.887 13.4235 108.807 13.4235H107.849C107.769 13.4235 107.716 13.3978 107.663 13.3463C107.609 13.2948 107.583 13.2433 107.583 13.1661V7.4508C107.583 7.37357 107.609 7.32208 107.663 7.27059C107.716 7.2191 107.769 7.19336 107.849 7.19336H115.115C115.781 7.19336 116.419 7.32208 117.032 7.55378C117.644 7.81123 118.176 8.17165 118.628 8.63505C119.054 9.09845 119.4 9.63908 119.613 10.2312C119.853 10.8748 119.986 11.5184 119.959 12.1878Z"
            fill="#263684"
          />
          <path
            d="M124.857 16.9505C124.857 17.0277 124.83 17.0792 124.777 17.1307C124.724 17.1822 124.644 17.208 124.564 17.208H123.526C123.446 17.208 123.366 17.1822 123.313 17.1307C123.26 17.0792 123.233 17.0277 123.233 16.9505V7.4508C123.233 7.37357 123.26 7.32208 123.313 7.27059C123.366 7.2191 123.446 7.19336 123.526 7.19336H124.564C124.644 7.19336 124.724 7.2191 124.777 7.27059C124.83 7.32208 124.857 7.37357 124.857 7.4508V16.9505Z"
            fill="#263684"
          />
          <path
            d="M140.64 16.9505C140.64 17.0277 140.613 17.0792 140.56 17.1307C140.507 17.1822 140.454 17.208 140.374 17.208H139.442C139.362 17.208 139.309 17.1822 139.256 17.1307C139.203 17.0792 139.176 17.0277 139.176 16.9505V14.6078C139.176 14.5305 139.15 14.479 139.096 14.4276C139.043 14.3761 138.99 14.3503 138.91 14.3503H133.8C133.72 14.3503 133.667 14.3246 133.613 14.2731C133.56 14.2216 133.534 14.1701 133.534 14.0929V13.1918C133.534 13.1146 133.56 13.0631 133.613 13.0116C133.667 12.9601 133.72 12.9344 133.8 12.9344H138.883C138.963 12.9344 139.016 12.9086 139.07 12.8571C139.123 12.8057 139.15 12.7542 139.15 12.6769V8.89249C139.15 8.81526 139.123 8.76377 139.07 8.71228C139.016 8.66079 138.963 8.63505 138.883 8.63505H133.853C133.347 8.63505 132.868 8.73803 132.416 8.91824C131.963 9.09845 131.564 9.3559 131.245 9.69057C130.899 10.0253 130.632 10.4114 130.446 10.8233C130.26 11.261 130.153 11.7244 130.153 12.2135V16.9505C130.153 17.0277 130.127 17.0792 130.073 17.1307C130.02 17.1822 129.967 17.208 129.887 17.208H128.956C128.876 17.208 128.822 17.1822 128.769 17.1307C128.716 17.0792 128.689 17.0277 128.689 16.9505V12.2135C128.689 11.3382 128.929 10.4629 129.381 9.69057C129.594 9.33015 129.887 8.96973 130.18 8.68654C130.499 8.37761 130.845 8.12016 131.245 7.88846C131.644 7.65676 132.043 7.50229 132.469 7.37357C132.921 7.24485 133.374 7.19336 133.853 7.19336H140.374C140.454 7.19336 140.507 7.2191 140.56 7.27059C140.613 7.32208 140.64 7.37357 140.64 7.4508V16.9505Z"
            fill="#263684"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3323_9012"
          x1="12.1369"
          y1="1.00202"
          x2="12.1369"
          y2="22.9929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FD6B51" />
          <stop offset="0.53" stop-color="#FF8718" />
          <stop offset="1" stop-color="#FFB018" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3323_9012"
          x1="12.1105"
          y1="19.0371"
          x2="12.1105"
          y2="23.4137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8BB8FF" />
          <stop offset="1" stop-color="#4C70DF" />
          <stop offset="1" stop-color="#4C70DF" />
        </linearGradient>
        <clipPath id="clip0_3323_9012">
          <rect
            width="108.221"
            height="10.0146"
            fill="white"
            transform="translate(32.4718 7.19336)"
          />
        </clipPath>
        <clipPath id="clip1_3323_9012">
          <rect
            width="108.221"
            height="10.0146"
            fill="white"
            transform="translate(32.4718 7.19336)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-if="checkScreenSize"
      width="196"
      height="32"
      viewBox="0 0 196 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5432 31.993C29.7249 32.0005 28.9329 31.7132 28.3214 31.1871C27.3245 30.2486 26.3987 29.2419 25.5514 28.1748C23.2296 25.406 20.3374 21.9674 16.9083 21.9638C13.4978 21.9638 10.5686 25.4167 8.21712 28.1927C7.36695 29.2519 6.44139 30.2525 5.44718 31.1871C4.8236 31.7154 4.02158 32.0046 3.19262 31.9999C2.36366 31.9953 1.56514 31.6972 0.947888 31.162C-0.107502 30.134 -0.288955 28.7371 0.440561 27.3223C0.559061 27.1002 11.7832 5.9566 13.5163 2.60758C13.9829 1.70137 14.8494 0.0286547 16.8491 0H16.8972C18.9117 0.0286547 19.7746 1.70137 20.23 2.60758C21.9631 5.9566 33.191 27.1002 33.3021 27.3223C34.0427 28.755 33.8538 30.1448 32.8022 31.162C32.1857 31.7066 31.378 32.0037 30.5432 31.993ZM16.9046 19.8147C21.3928 19.8147 24.6553 23.6975 27.2734 26.8172C28.0507 27.7978 28.897 28.7253 29.8063 29.5932C30.0105 29.7481 30.261 29.8353 30.5205 29.8418C30.7799 29.8482 31.0347 29.7736 31.2468 29.629C31.4912 29.3962 31.7208 29.0272 31.3357 28.2822C31.232 28.0816 19.9968 6.92728 18.2674 3.58184C17.6564 2.38551 17.3268 2.1491 16.8861 2.1491C16.4455 2.1491 16.1159 2.38551 15.5012 3.58184C13.7644 6.94161 2.54023 28.096 2.42173 28.2965C2.05142 29.0308 2.27361 29.3997 2.51801 29.6326C2.73085 29.7756 2.98528 29.8492 3.24433 29.8427C3.50339 29.8363 3.75357 29.7501 3.95853 29.5967C4.86786 28.7319 5.71652 27.8093 6.49887 26.8351C9.13549 23.7225 12.4239 19.8506 16.8676 19.8183H16.9195L16.9046 19.8147Z"
        fill="url(#paint0_linear_3323_25477)"
      />
      <ellipse
        cx="16.8493"
        cy="28.8336"
        rx="3.14766"
        ry="3.04456"
        fill="url(#paint1_linear_3323_25477)"
      />
      <g clip-path="url(#clip0_3323_25477)">
        <g clip-path="url(#clip1_3323_25477)">
          <path
            d="M61.8423 22.8877C61.8423 22.9951 61.8052 23.0668 61.7312 23.1384C61.6571 23.21 61.5831 23.2459 61.472 23.2459H60.1388C60.0277 23.2459 59.9537 23.21 59.8796 23.1384C59.8056 23.0668 59.7685 22.9951 59.7685 22.8877V19.6282C59.7685 19.5207 59.7315 19.4491 59.6574 19.3775C59.5834 19.3058 59.5093 19.27 59.3982 19.27H52.3252C52.2142 19.27 52.1401 19.2342 52.066 19.1626C51.992 19.0909 51.9549 19.0193 51.9549 18.9118V17.6582C51.9549 17.5507 51.992 17.4791 52.066 17.4075C52.1401 17.3358 52.2142 17.3 52.3252 17.3H59.4352C59.5463 17.3 59.6204 17.2642 59.6945 17.1925C59.7685 17.1209 59.8056 17.0493 59.8056 16.9418V11.6765C59.8056 11.5691 59.7685 11.4974 59.6945 11.4258C59.6204 11.3541 59.5463 11.3183 59.4352 11.3183H52.3993C51.6957 11.3183 51.0292 11.4616 50.3996 11.7123C49.8071 11.9631 49.2517 12.3212 48.7703 12.7869C48.2888 13.2525 47.9185 13.7898 47.6593 14.3629C47.4001 14.9718 47.252 15.6165 47.252 16.2971V22.8877C47.252 22.9951 47.2149 23.0668 47.1409 23.1384C47.0668 23.21 46.9928 23.2459 46.8817 23.2459H45.5485C45.4374 23.2459 45.3634 23.21 45.2893 23.1384C45.2153 23.0668 45.1782 22.9951 45.1782 22.8877V16.2971C45.1782 15.6882 45.2523 15.0434 45.4374 14.4703C45.6226 13.8973 45.8448 13.3242 46.1781 12.8227C46.4743 12.3212 46.8817 11.8198 47.289 11.4258C47.7334 10.996 48.2148 10.6378 48.7703 10.3154C49.3257 9.99305 49.8812 9.77814 50.4737 9.59905C51.1032 9.38414 51.7698 9.3125 52.3993 9.3125H61.472C61.5831 9.3125 61.6571 9.34832 61.7312 9.41996C61.8052 9.49159 61.8423 9.56323 61.8423 9.67068V22.8877Z"
            fill="#263684"
          />
          <path
            d="M82.728 22.8877C82.728 22.9951 82.6909 23.0668 82.6169 23.1384C82.5428 23.21 82.4688 23.2459 82.3577 23.2459H73.3591C72.3963 23.2459 71.4705 23.0668 70.5817 22.7086C69.767 22.3504 69.0634 21.8489 68.4709 21.24C67.8784 20.5953 67.397 19.8431 67.1008 19.0193C66.7675 18.1596 66.6194 17.2284 66.6194 16.2971C66.6194 15.3658 66.7675 14.4703 67.1008 13.6107C67.397 12.7869 67.8784 12.0347 68.4709 11.39C69.0634 10.7452 69.767 10.2438 70.5817 9.88559C71.4705 9.49159 72.3963 9.3125 73.3591 9.3125H82.3577C82.4688 9.3125 82.5428 9.34832 82.6169 9.41996C82.6909 9.49159 82.728 9.56323 82.728 9.67068V10.9243C82.728 11.0318 82.6909 11.1034 82.6169 11.1751C82.5428 11.2467 82.4688 11.2825 82.3577 11.2825H73.3591C72.6925 11.2825 72.0259 11.4258 71.3964 11.6765C70.8409 11.9272 70.3225 12.2854 69.9522 12.7511C69.5449 13.2167 69.2486 13.754 69.0264 14.3271C68.8042 14.936 68.6931 15.6165 68.7302 16.2613C68.7302 16.906 68.8413 17.5865 69.0264 18.1955C69.2486 18.7686 69.5449 19.3058 69.9522 19.7715C70.3595 20.2371 70.8409 20.5953 71.3964 20.846C71.9889 21.0968 72.6555 21.24 73.3591 21.24H82.3577C82.4688 21.24 82.5428 21.2758 82.6169 21.3475C82.6909 21.4191 82.728 21.4908 82.728 21.5982V22.8877Z"
            fill="#263684"
          />
          <path
            d="M103.651 22.8883C103.651 22.9958 103.614 23.0674 103.54 23.1391C103.465 23.2107 103.391 23.2465 103.28 23.2465H101.984C101.873 23.2465 101.799 23.2107 101.725 23.1391C101.651 23.0674 101.614 22.9958 101.614 22.8883V19.6289C101.614 19.5214 101.577 19.4498 101.503 19.3781C101.429 19.3065 101.355 19.2707 101.244 19.2707H88.8381C88.727 19.2707 88.653 19.2349 88.5789 19.1632C88.5049 19.0916 88.4678 19.0199 88.4678 18.9125V17.6588C88.4678 17.5514 88.5049 17.4798 88.5789 17.4081C88.653 17.3365 88.727 17.3007 88.8381 17.3007H101.207C101.318 17.3007 101.392 17.2648 101.466 17.1932C101.54 17.1216 101.577 17.0499 101.577 16.9425V11.6772C101.577 11.5697 101.54 11.4981 101.466 11.4264C101.392 11.3548 101.318 11.319 101.207 11.319H94.1706C93.5041 11.319 92.8005 11.4623 92.1709 11.713C91.5414 11.9637 90.9859 12.3219 90.5416 12.7875C90.0602 13.2532 89.6899 13.7905 89.4306 14.3636C89.1714 14.9725 89.0233 15.6172 89.0233 16.2977V22.8883C89.0233 22.9958 88.9863 23.0674 88.9122 23.1391C88.8381 23.2107 88.7641 23.2465 88.653 23.2465H87.3569C87.2458 23.2465 87.1717 23.2107 87.0977 23.1391C87.0236 23.0674 86.9866 22.9958 86.9866 22.8883V16.2619C86.9866 15.653 87.0606 15.0083 87.2458 14.3994C87.5791 13.2174 88.2086 12.1786 89.0974 11.319C89.9861 10.4594 91.06 9.85044 92.282 9.52807C92.9116 9.34898 93.5411 9.27734 94.2077 9.27734H103.317C103.428 9.27734 103.503 9.31316 103.577 9.3848C103.651 9.45644 103.688 9.52807 103.688 9.63553L103.651 22.8883Z"
            fill="#263684"
          />
          <path
            d="M125.943 14.2554C125.943 14.936 125.795 15.5807 125.536 16.1896C125.018 17.3716 124.018 18.3387 122.796 18.8402C122.166 19.0909 121.463 19.2342 120.796 19.2342H115.871C115.76 19.2342 115.686 19.1984 115.612 19.1267C115.538 19.0551 115.501 18.9835 115.501 18.876V17.6582C115.501 17.5507 115.538 17.4791 115.612 17.4075C115.686 17.3358 115.76 17.3 115.871 17.3H120.796C121.203 17.3 121.611 17.2284 122.018 17.0851C122.759 16.7627 123.351 16.1896 123.647 15.4733C123.796 15.1151 123.907 14.6853 123.907 14.2913C123.907 13.8973 123.833 13.5033 123.647 13.1093C123.499 12.7511 123.277 12.4287 122.981 12.178C122.685 11.8914 122.351 11.6765 121.981 11.5332C121.611 11.39 121.203 11.3183 120.796 11.3183H111.723C111.612 11.3183 111.538 11.3541 111.464 11.4258C111.39 11.4974 111.353 11.5691 111.353 11.6765V22.8877C111.353 22.9951 111.316 23.0668 111.242 23.1384C111.168 23.21 111.094 23.2459 110.983 23.2459H109.687C109.576 23.2459 109.502 23.21 109.428 23.1384C109.353 23.0668 109.316 22.9951 109.316 22.8877V9.67068C109.316 9.56323 109.353 9.49159 109.428 9.41996C109.502 9.34832 109.576 9.3125 109.687 9.3125H120.833C121.537 9.3125 122.203 9.45577 122.833 9.7065C124.055 10.208 125.055 11.1751 125.573 12.3571C125.832 12.966 125.98 13.6107 125.943 14.2554Z"
            fill="#263684"
          />
          <path
            d="M145.163 22.8877C145.163 22.9951 145.125 23.0668 145.051 23.1384C144.977 23.21 144.903 23.2459 144.792 23.2459H130.572C130.461 23.2459 130.387 23.21 130.313 23.1384C130.239 23.0668 130.202 22.9951 130.202 22.8877V9.67068C130.202 9.56323 130.239 9.49159 130.313 9.41996C130.387 9.34832 130.461 9.3125 130.572 9.3125H144.792C144.903 9.3125 144.977 9.34832 145.051 9.41996C145.125 9.49159 145.163 9.56323 145.163 9.67068V10.9243C145.163 11.0318 145.125 11.1034 145.051 11.1751C144.977 11.2467 144.903 11.2825 144.792 11.2825H132.646C132.535 11.2825 132.461 11.3183 132.387 11.39C132.313 11.4616 132.276 11.5332 132.276 11.6407V20.8818C132.276 20.9893 132.313 21.0609 132.387 21.1326C132.461 21.2042 132.535 21.24 132.646 21.24H144.792C144.903 21.24 144.977 21.2758 145.051 21.3475C145.125 21.4191 145.163 21.4908 145.163 21.5982V22.8877ZM143.422 16.906C143.422 17.0135 143.385 17.0851 143.311 17.1567C143.237 17.2284 143.163 17.2642 143.052 17.2642H131.72C131.609 17.2642 131.535 17.2284 131.461 17.1567C131.387 17.0851 131.35 17.0135 131.35 16.906V15.6524C131.35 15.5449 131.387 15.4733 131.461 15.4016C131.535 15.33 131.609 15.2942 131.72 15.2942H143.015C143.126 15.2942 143.2 15.33 143.274 15.4016C143.348 15.4733 143.385 15.5449 143.385 15.6524L143.422 16.906Z"
            fill="#263684"
          />
          <path
            d="M166.9 16.2613C166.9 17.1925 166.752 18.1238 166.419 18.9835C166.122 19.8073 165.641 20.5595 165.048 21.2042C164.419 21.8489 163.678 22.3862 162.827 22.7086C161.975 23.0668 161.086 23.2459 160.16 23.21H150.051C149.94 23.21 149.866 23.1742 149.792 23.1026C149.717 23.0309 149.68 22.9593 149.68 22.8519V21.5982C149.68 21.4908 149.717 21.4191 149.792 21.3475C149.866 21.2758 149.94 21.24 150.051 21.24H160.197C160.864 21.24 161.53 21.1326 162.123 20.846C162.678 20.5953 163.197 20.2371 163.567 19.7715C163.974 19.3058 164.271 18.7686 164.493 18.1955C164.715 17.5865 164.826 16.906 164.826 16.2613C164.826 15.6165 164.715 14.936 164.493 14.3271C164.308 13.754 163.974 13.2167 163.567 12.7511C163.16 12.2854 162.678 11.9272 162.123 11.6765C161.53 11.39 160.864 11.2825 160.197 11.2825H152.124C152.013 11.2825 151.939 11.3183 151.865 11.39C151.791 11.4616 151.754 11.5332 151.754 11.6407V17.6224C151.754 17.7298 151.717 17.8015 151.643 17.8731C151.569 17.9447 151.495 17.9806 151.384 17.9806H150.051C149.94 17.9806 149.866 17.9447 149.792 17.8731C149.717 17.8015 149.68 17.7298 149.68 17.6224V9.67068C149.68 9.56323 149.717 9.49159 149.792 9.41996C149.866 9.34832 149.94 9.3125 150.051 9.3125H160.16C161.086 9.3125 161.975 9.49159 162.827 9.81396C163.678 10.1721 164.419 10.6736 165.048 11.3183C165.641 11.9631 166.122 12.7152 166.419 13.5391C166.752 14.4345 166.937 15.33 166.9 16.2613Z"
            fill="#263684"
          />
          <path
            d="M173.714 22.8877C173.714 22.9951 173.676 23.0668 173.602 23.1384C173.528 23.21 173.417 23.2459 173.306 23.2459H171.862C171.751 23.2459 171.64 23.21 171.566 23.1384C171.492 23.0668 171.455 22.9951 171.455 22.8877V9.67068C171.455 9.56323 171.492 9.49159 171.566 9.41996C171.64 9.34832 171.751 9.3125 171.862 9.3125H173.306C173.417 9.3125 173.528 9.34832 173.602 9.41996C173.676 9.49159 173.714 9.56323 173.714 9.67068V22.8877Z"
            fill="#263684"
          />
          <path
            d="M195.673 22.8877C195.673 22.9951 195.636 23.0668 195.562 23.1384C195.488 23.21 195.414 23.2459 195.303 23.2459H194.007C193.896 23.2459 193.822 23.21 193.748 23.1384C193.673 23.0668 193.636 22.9951 193.636 22.8877V19.6282C193.636 19.5207 193.599 19.4491 193.525 19.3775C193.451 19.3058 193.377 19.27 193.266 19.27H186.156C186.045 19.27 185.971 19.2342 185.897 19.1626C185.823 19.0909 185.786 19.0193 185.786 18.9118V17.6582C185.786 17.5507 185.823 17.4791 185.897 17.4075C185.971 17.3358 186.045 17.3 186.156 17.3H193.229C193.34 17.3 193.414 17.2642 193.488 17.1925C193.562 17.1209 193.599 17.0493 193.599 16.9418V11.6765C193.599 11.5691 193.562 11.4974 193.488 11.4258C193.414 11.3541 193.34 11.3183 193.229 11.3183H186.23C185.527 11.3183 184.86 11.4616 184.231 11.7123C183.601 11.9631 183.046 12.3212 182.601 12.7869C182.12 13.2525 181.749 13.7898 181.49 14.3629C181.231 14.9718 181.083 15.6165 181.083 16.2971V22.8877C181.083 22.9951 181.046 23.0668 180.972 23.1384C180.898 23.21 180.824 23.2459 180.713 23.2459H179.416C179.305 23.2459 179.231 23.21 179.157 23.1384C179.083 23.0668 179.046 22.9951 179.046 22.8877V16.2971C179.046 15.0793 179.379 13.8614 180.009 12.7869C180.305 12.2854 180.713 11.784 181.12 11.39C181.564 10.9601 182.046 10.602 182.601 10.2796C183.157 9.95723 183.712 9.74232 184.305 9.56323C184.934 9.38414 185.564 9.3125 186.23 9.3125H195.303C195.414 9.3125 195.488 9.34832 195.562 9.41996C195.636 9.49159 195.673 9.56323 195.673 9.67068V22.8877Z"
            fill="#263684"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3323_25477"
          x1="16.8861"
          y1="0.698459"
          x2="16.8861"
          y2="31.2945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FD6B51" />
          <stop offset="0.53" stop-color="#FF8718" />
          <stop offset="1" stop-color="#FFB018" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3323_25477"
          x1="16.8493"
          y1="25.7891"
          x2="16.8493"
          y2="31.8782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8BB8FF" />
          <stop offset="1" stop-color="#4C70DF" />
          <stop offset="1" stop-color="#4C70DF" />
        </linearGradient>
        <clipPath id="clip0_3323_25477">
          <rect
            width="150.569"
            height="13.9334"
            fill="white"
            transform="translate(45.1782 9.3125)"
          />
        </clipPath>
        <clipPath id="clip1_3323_25477">
          <rect
            width="150.569"
            height="13.9334"
            fill="white"
            transform="translate(45.1782 9.3125)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  computed: {
    checkScreenSize() {
      return this.$vuetify.breakpoint.width > 1100 ? true : false;
    },
  },
};
</script>
