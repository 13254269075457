<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.678612 31.7724C0.226204 28.4637 0 25.1739 0 21.9028C0 18.6322 0.226143 15.3428 0.678429 12.0346C0.779119 11.2981 1.15785 10.6305 1.73433 10.1732C4.30924 8.13073 6.99707 6.28718 9.7978 4.64254C12.5993 2.99746 15.5138 1.5514 18.5412 0.304367C19.2177 0.0257192 19.9742 0.0254897 20.6508 0.303726C23.6619 1.54189 26.5761 2.98816 29.3934 4.64254C32.2104 6.2967 34.8986 8.14018 37.4581 10.173C38.0338 10.6302 38.412 11.2974 38.5126 12.0333C38.965 15.3419 39.1912 18.6317 39.1912 21.9028C39.1912 25.1734 38.9651 28.4628 38.5128 31.771C38.4121 32.5075 38.0334 33.1751 37.4569 33.6324C34.882 35.6749 32.1941 37.5184 29.3934 39.1631C26.5919 40.8082 23.6774 42.2542 20.65 43.5013C19.9735 43.7799 19.217 43.7801 18.5404 43.5019C15.5293 42.2637 12.6151 40.8175 9.7978 39.1631C6.98085 37.5089 4.29263 35.6654 1.73315 33.6326C1.15743 33.1754 0.779231 32.5082 0.678612 31.7724Z"
      fill="#50B5FF"
    />
    <mask
      id="mask0"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :width="width"
      :height="height"
    >
      <path
        d="M0.678612 31.7724C0.226204 28.4637 0 25.1739 0 21.9028C0 18.6322 0.226143 15.3428 0.678429 12.0346C0.779119 11.2981 1.15785 10.6305 1.73433 10.1732C4.30924 8.13073 6.99707 6.28718 9.7978 4.64254C12.5993 2.99746 15.5138 1.5514 18.5412 0.304367C19.2177 0.0257192 19.9742 0.0254897 20.6508 0.303726C23.6619 1.54189 26.5761 2.98816 29.3934 4.64254C32.2104 6.2967 34.8986 8.14018 37.4581 10.173C38.0338 10.6302 38.412 11.2974 38.5126 12.0333C38.965 15.3419 39.1912 18.6317 39.1912 21.9028C39.1912 25.1734 38.9651 28.4628 38.5128 31.771C38.4121 32.5075 38.0334 33.1751 37.4569 33.6324C34.882 35.6749 32.1941 37.5184 29.3934 39.1631C26.5919 40.8082 23.6774 42.2542 20.65 43.5013C19.9735 43.7799 19.217 43.7801 18.5404 43.5019C15.5293 42.2637 12.6151 40.8175 9.7978 39.1631C6.98085 37.5089 4.29263 35.6654 1.73315 33.6326C1.15743 33.1754 0.779231 32.5082 0.678612 31.7724Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
    <path
      d="M27.8017 20.3441V25.5018L19.7142 32.431L11.6754 28.747V20.3441H27.8017Z"
      fill="#5A5A5A"
    />
    <path
      d="M27.8017 20.3441V28.747L19.7142 32.431V20.3441H27.8017Z"
      fill="#444444"
    />
    <path
      d="M30.7249 18.1337V26.2465H29.2633V18.6407L30.7249 18.1337Z"
      fill="#FFD15C"
    />
    <path
      d="M30.7249 18.1337V26.2465H29.9941V18.3872L30.7249 18.1337Z"
      fill="#F8B64C"
    />
    <path
      d="M29.9942 24.0087L27.8018 26.2191V31.7354H32.1866V26.2191L29.9942 24.0087Z"
      fill="#FFD15C"
    />
    <path
      d="M32.1865 26.2191V31.7354H29.9941V24.0087L32.1865 26.2191Z"
      fill="#F8B64C"
    />
    <path
      d="M30.9629 17.4783C31.4852 17.758 31.484 18.5072 30.9609 18.7853L19.7142 24.765L8.46754 18.7853C7.94445 18.5072 7.94331 17.758 8.46554 17.4783L18.665 12.0152C19.3204 11.6642 20.108 11.6642 20.7635 12.0152L30.9629 17.4783Z"
      fill="#6E6E6E"
    />
    <path
      d="M30.9629 17.4783C31.4852 17.758 31.484 18.5072 30.9609 18.7853L19.7142 24.765V12.6903C19.7142 12.1305 20.3113 11.773 20.8047 12.0373L30.9629 17.4783Z"
      fill="#5A5A5A"
    />
    <path
      d="M19.7142 15.9232C18.0771 15.9232 16.791 16.8959 16.791 18.1337C16.791 19.3715 18.0771 20.3441 19.7142 20.3441C21.3513 20.3441 22.6374 19.3715 22.6374 18.1337C22.6374 16.8959 21.3513 15.9232 19.7142 15.9232Z"
      fill="#FFD15C"
    />
    <path
      d="M22.6374 18.1337C22.6374 19.3715 21.3513 20.3441 19.7142 20.3441V15.9232C21.3513 15.9232 22.6374 16.8959 22.6374 18.1337Z"
      fill="#F8B64C"
    />
  </svg>
</template>
<script>
  export default {
    props: ['width', 'height']
  }
</script>
