import {doGetArticle, doPostUpVoteArticle, doPostDownVoteArticle, doGetArticleQuiz, doPostQuizAnswer } from "@/api/article";

export default {
  namespaced: true,
  actions: {
    fetchArticle(context,articleId) {
      return doGetArticle(articleId);
    },
    fetchArticleQuiz(context,articleId) {
      return doGetArticleQuiz(articleId);
    },
    postQuizAnswer(context,payload) {
      return doPostQuizAnswer(payload);
    },
    upVoteArticle(context,articleId) {
      return doPostUpVoteArticle(articleId);
    },
    downVoteArticle(context,articleId) {
      return doPostDownVoteArticle(articleId);
    },
  }
}
