<template>
  <div
    v-if="hasToShowOnboarding"
    class="onboarding-panel onboarding-panel-size border-radius-small d-flex"
    :class="{ 'panel-size-increased-on-specialties': hasToShowSpecialtyStep,
              'panel-size-increased-on-keywords': hasToShowKeywordsPanel }"
  >
    <v-row
      v-if="hasToShowWelcomeSteps"
      ref="onboardingPanel"
      class="justify-space-around fix-last-step-overflow py-5 px-6 px-sm-10 mb-0"
      :class="{ 'my-2': $vuetify.breakpoint.smAndUp }"
    >
      <v-col
        class="col-12 col-sm-4 text-center"
        :class="{ 'adjust-images': $vuetify.breakpoint.smAndUp }"
      >
        <OnboardingImagesHandlerComponent
          :current-step-index="currentStepIndex"
        />
      </v-col>
      <v-col
        class="right-side-onboarding col-12 col-sm-8 pl-3 pb-0 pb-sm-3"
      >
        <v-row
          no-gutters
          align="center"
        >
          <div
            v-show="currentStepIndex > enumOnboarding.INITIAL_STEP && currentStepIndex < enumOnboarding.FINAL_STEP"
            class="heading number-step-box mr-2 primary--text"
            :class="calculateClassForNumber"
          >
            <span>{{ currentStepIndex }}</span>
          </div>
          <h3
            class="heading primary--text"
            :class="calculateClassForTitle"
          >
            {{ items[currentStepIndex].title }}
          </h3>
          <v-spacer />
        </v-row>
        <v-row no-gutters>
          <p
            ref="descriptions"
            class="descriptions mt-2 mb-4 text text-l-large-regular word-wrap-all"
            :class="{ 'pl-sm-15 pl-lg-8': !$vuetify.breakpoint.xsOnly && currentStepIndex === enumOnboarding.FINAL_STEP }"
            v-html="items[currentStepIndex].desc"
          />
        </v-row>
        <v-row
          no-gutters
          class="mt-sm-0"
          justify-sm="space-between"
          :class="currentStepIndex === enumOnboarding.FINAL_STEP ? 'mt-0' : 'mt-14'"
        >
          <v-col
            v-show="currentStepIndex < enumOnboarding.FINAL_STEP && !$vuetify.breakpoint.xsOnly"
            cols="12"
            sm="auto"
            align-self="center"
          >
            <div class="d-flex">
              <DotComponent
                v-for="index in 4"
                :key="index"
                :index="index - 1"
                :current-step-index="currentStepIndex"
                class="clickable"
                @clicked="goToStep(index - 1)"
              />
            </div>
          </v-col>
          <v-col
            class="col-12 text-right align-self-center"
            :class="{ 'col-sm-12 mb-sm-1': currentStepIndex === enumOnboarding.FINAL_STEP || currentStepIndex === enumOnboarding.PLANS_STEP, 'col-sm-auto': currentStepIndex !== enumOnboarding.FINAL_STEP,
                      'buttons-disposition-mobile': $vuetify.breakpoint.xsOnly }"
          >
            <v-btn
              v-show="hasToShowBackButton"
              ripple
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              :height="$vuetify.breakpoint.xsOnly ? '44px' :'36px'"
              color="grey lighten-5"
              class="primary--text heading heading-s-small align-self-center unaltered-text-cap letter-spacing-normal shadow-light-button px-4 mr-sm-4"
              @click="goStepBack"
            >
              Back
            </v-btn>
            <v-btn
              v-show="currentStepIndex === enumOnboarding.PLANS_STEP"
              ripple
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              :height="$vuetify.breakpoint.xsOnly ? '44px' :'36px'"
              color="grey lighten-5"
              class="primary--text heading heading-s-small align-self-center unaltered-text-cap letter-spacing-normal shadow-light-button px-4 mr-sm-4"
              @click="finishOnboardingFlow"
            >
              Do it later
            </v-btn>
            <v-btn
              v-show="currentStepIndex === enumOnboarding.INITIAL_STEP"
              ripple
              :text="!$vuetify.breakpoint.xsOnly"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              :height="$vuetify.breakpoint.xsOnly ? '44px' :'36px'"
              :color="$vuetify.breakpoint.xsOnly ? 'grey lighten-5':''"
              class="primary--text heading heading-s-small align-self-center unaltered-text-cap mr-sm-4"
              :class="{ 'shadow-light-button' : $vuetify.breakpoint.xsOnly }"
              @click="skipOnboarding"
            >
              Skip
            </v-btn>
            <v-btn
              ripple
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              :height="$vuetify.breakpoint.xsOnly ? '44px' :'36px'"
              color="primary"
              class="heading heading-s-small align-self-center unaltered-text-cap shadow-light-button px-6 mb-4 mb-sm-0"
              @click="nextStep"
            >
              {{ items[currentStepIndex].btnPrimary }}
            </v-btn>
          </v-col>
          <v-col
            v-show="currentStepIndex < enumOnboarding.FINAL_STEP && $vuetify.breakpoint.xsOnly"
            class="justify-center mt-8"
            :class="{ 'd-flex': currentStepIndex < enumOnboarding.FINAL_STEP && $vuetify.breakpoint.xsOnly }"
            cols="12"
            sm="4"
            align-self="center"
          >
            <div class="d-flex">
              <DotComponent
                v-for="index in 4"
                :key="index"
                :index="index - 1"
                :current-step-index="currentStepIndex"
                class="clickable"
                @clicked="goToStep(index - 1)"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row 
      v-if="hasToShowSpecialtyStep"
      class="justify-space-around fix-last-step-overflow py-5 px-4 px-sm-10 mb-0"
      :class="{ 'my-2': $vuetify.breakpoint.smAndUp }"
    >
      <SpecialtyListComponent
        @show-again-welcome-steps="goStepBack"
        @finish-specialties-selection="nextStep"
      />
    </v-row>
    <v-row 
      v-if="hasToShowKeywordsPanel"
      class="justify-space-around fix-last-step-overflow py-5 px-4 px-sm-10 mb-0"
      :class="{ 'my-2': $vuetify.breakpoint.smAndUp }"
    >
      <KeywordListComponent 
        @show-again-welcome-steps="goStepBack"
        @finish-keywords-selection="nextStep"
      />
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OnboardingImagesHandlerComponent from "@/components/onboarding/images/OnboardingImagesHandlerComponent";
import SpecialtyListComponent from "@/components/specialties/SpecialtyListComponent";
import KeywordListComponent from "@/components/keywords/KeywordListComponent";
import DotComponent from "@/components/common/DotComponent";
export default {
  components: { DotComponent, OnboardingImagesHandlerComponent, SpecialtyListComponent, KeywordListComponent },
  props: ["currentRoute", "currentUser"],
  data: () => ({
    currentStepIndex: 0,
    items: [
      { id: "0", title: "Welcome to Acapedia Professionals", desc: "Where the brightest minds of the world share knowledge through evidenced based publication while fulfilling professional requirements", btnPrimary: "Learn more", btnSecondary: "Skip" },
      { id: "1", title: "Read an interesting article", desc: "Find articles relevant to your specialty and interests from a curated feed.", btnPrimary: "Next", btnSecondary: "Back" },
      { id: "2", title: "Take a 5-question quiz", desc: "Once you’ve read an article, take a short 5 question quiz to validate your understanding. You will have 3 attempts to revise your quiz responses. ", btnPrimary: "Next", btnSecondary: "Back" },
      { id: "3", title: "Get your CME credit!", desc: "If you pass, you get CME credit and, depending on your ABMS board, MOC credit is awarded through an accredited provider. You can view your credits in your transcript.", btnPrimary: "Customize feed", btnSecondary: "Back" },
      { id: "4", title: "Now it’s time to customize your Feed", desc: "Select your specialty in order to see articles that may interest you.", btnPrimary: "Select specialty", btnSecondary: "Back" },
      { id: "5", title: "Specialties" },
      { id: "6", title: "Interests" },
      { id: "7", title: "Now you're ready to earn CME!", desc: "We've set up your account so you can earn up to 5 CME credits for free.  If you want to earn more you can refer colleagues or purchase a paid subscription.", btnPrimary: "See paid plans", btnSecondary: "Do it later" },
    ],
    enumOnboarding: {
      INITIAL_STEP: 0,
      FINAL_STEP: 4,
      SPECIALTY_STEP: 5,
      KEYWORDS_STEP: 6,
      PLANS_STEP: 7
    },
    descriptionClasses: "descriptions mt-2 mb-4 text text-l-large-regular",
    animatedClass: "animate-text",
    mountedDone: false,
    touchendX: 0,
    touchstartX: 0,
    touchendY: 0,
    touchstartY: 0,
    touchEventsDone: false
  }),
  computed: {
    ...mapGetters("feed", ["isOnboardingFinished", "isOnboardingRequestDone"]),
    hasToShowOnboarding() {

      if (!this.isOnboardingFinished && this.isOnboardingRequestDone && this.currentUser && (this.currentRoute === "home" || this.currentRoute === "/")) {
        this.setGeneralVeil(true);
        document.querySelector(':root').style["overflow-y"] = "hidden";
        return true;
      }

      return false;
    },
    hasToShowWelcomeSteps() {
      return this.currentStepIndex <= this.enumOnboarding.FINAL_STEP || this.currentStepIndex === this.enumOnboarding.PLANS_STEP;
    },
    hasToShowSpecialtyStep() {
      return this.currentStepIndex === this.enumOnboarding.SPECIALTY_STEP;
    },
    hasToShowKeywordsPanel() {
      return this.currentStepIndex === this.enumOnboarding.KEYWORDS_STEP;
    },
    hasToShowBackButton() {

      const xsOnly = this.$vuetify.breakpoint.xsOnly;
      const { INITIAL_STEP, PLANS_STEP, FINAL_STEP } = this.enumOnboarding;

      if (!xsOnly) {
        return ![INITIAL_STEP, PLANS_STEP].includes(this.currentStepIndex);
      }

      if (xsOnly) {
        return ![INITIAL_STEP, PLANS_STEP, FINAL_STEP].includes(this.currentStepIndex);
      }

      return false;
    },
    calculateClassForTitle() {

      if ( this.$vuetify.breakpoint.width <= 320 && this.currentStepIndex < this.enumOnboarding.FINAL_STEP) {
        return 'heading-m-small onboarding-title-regular-mobile';
      }

      if ( this.$vuetify.breakpoint.width <= 320 && (this.currentStepIndex === this.enumOnboarding.FINAL_STEP || this.currentStepIndex === this.enumOnboarding.PLANS_STEP)) {
        return 'heading-l-small';
      }

      if ( this.$vuetify.breakpoint.width > 320 && this.$vuetify.breakpoint.xsOnly && (this.currentStepIndex === this.enumOnboarding.FINAL_STEP || this.currentStepIndex === this.enumOnboarding.PLANS_STEP) ) {
        return 'heading-l-large onboarding-title-regular-mobile';
      }

      if ( this.$vuetify.breakpoint.smAndUp && ![this.enumOnboarding.INITIAL_STEP, this.enumOnboarding.FINAL_STEP, this.enumOnboarding.PLANS_STEP].includes(this.currentStepIndex) ) {
        return 'heading-m-small onboarding-title-regular-mobile';
      }

      if ( this.$vuetify.breakpoint.smAndUp && this.currentStepIndex === this.enumOnboarding.FINAL_STEP ) {
        return 'heading-m-large onboarding-title-regular-mobile pl-sm-15 pl-lg-8';
      } else if( this.$vuetify.breakpoint.smAndUp && this.currentStepIndex === this.enumOnboarding.PLANS_STEP ) {
        return 'heading-m-large onboarding-title-regular-mobile';
      }

      if ( this.$vuetify.breakpoint.smAndUp && this.currentStepIndex === this.enumOnboarding.INITIAL_STEP ) {
        return 'heading-m-large onboarding-title-regular-mobile';
      }

      return "";
    },
    calculateClassForNumber() {

      if ( this.$vuetify.breakpoint.width <= 320 ) {
        return 'heading-m-small';
      }

      if ( this.$vuetify.breakpoint.width > 320 && this.$vuetify.breakpoint.xsOnly  ) {
        return 'heading-m-large';
      }

      if (this.$vuetify.breakpoint.smAndUp ) {
        return 'heading-m-small';
      }

      return "";

    }
  },
  updated() {

    if(this.$refs.onboardingPanel && !this.touchEventsDone) {
      const that = this;
      this.$refs.onboardingPanel.addEventListener('touchstart', function (event) {
        that.touchstartX = event.changedTouches[0].screenX;
        that.touchstartY = event.changedTouches[0].screenY;
      }, false);

      this.$refs.onboardingPanel.addEventListener('touchend', function (event) {
        that.touchendX = event.changedTouches[0].screenX;
        that.touchendY = event.changedTouches[0].screenY;

        that.handleGesture();
      }, false);

      this.touchEventsDone = true;
    }

    if(this.$refs.descriptions && !this.mountedDone) {
      this.$refs.descriptions.addEventListener("animationend", () => {
        this.$refs.descriptions.className = this.descriptionClasses;
      });

      this.mountedDone = true;
    }
  },
  methods: {
    ...mapActions("feed", ["finishOnboarding"]),
    ...mapActions("menu", ["setGeneralVeil"]),
    nextStep() {

      if (this.currentStepIndex === this.enumOnboarding.PLANS_STEP) {
        this.finishOnboardingFlow();
      }

      if (this.currentStepIndex < this.enumOnboarding.PLANS_STEP) {
        this.currentStepIndex++;
      }

      if (this.$refs.descriptions) {
        this.$refs.descriptions.className = `${this.descriptionClasses} ${this.animatedClass}`;
      }
    },
    goStepBack() {
      if (this.currentStepIndex > this.enumOnboarding.INITIAL_STEP) {
        this.currentStepIndex--;
      }
    },
    closeOnboarding() {
      this.finishOnboardingFlow();
    },
    finishOnboardingFlow() {
      this.finishOnboarding();
      this.setGeneralVeil(false);
      document.querySelector(':root').style["overflow-y"] = "initial";
    },
    goToStep(index) {
      this.currentStepIndex = index;
      this.$refs.descriptions.className = `${this.descriptionClasses} ${this.animatedClass}`;
    },
    skipOnboarding() {
      this.currentStepIndex = this.enumOnboarding.FINAL_STEP;
    },
    handleGesture() {

      const passThresholdTolerance = Math.abs(this.touchendY - this.touchstartY) < 100 && Math.abs(this.touchendX - this.touchstartX) > 50;

      if(this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) {
        if (this.touchendX < this.touchstartX && passThresholdTolerance ) {
          this.nextStep();
        }

        if (this.touchendX > this.touchstartX && passThresholdTolerance ) {
          this.goStepBack();
        }
      }
    }
  }
}
</script>

<style scoped>
.onboarding-panel {
  position: fixed;
  background-color: var(--v-background-base);
  z-index: 99;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
}
.right-side-onboarding {
  flex-direction: column;
  display: flex;
}
.onboarding-panel p.descriptions {
  height: 60px;
}
.buttons-disposition-mobile {
  display: flex;
  flex-direction: column-reverse;
}
.fix-last-step-overflow {
  overflow-x: hidden;
}
.adjust-images {
  display: flex;
  align-items: center;
}
@keyframes keyframe-animation-display {
  from {
    opacity: 0;
    transform: translateX(15px);
  }
  to {
    opacity: 1.0;
    transform: translateX(0px);
  }
}
.animate-text {
  animation-name: keyframe-animation-display;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.number-step-box {
  background: #FFFFFF;
  border-radius: 32px;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .onboarding-panel-size {
    width: 100%;
    height: 100%;
  }
  .onboarding-panel-size > .row {
    background-color: var(--v-background-base);
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  .onboarding-panel-size {
    height: 28vh;
    min-height: 292px;
    top: 35vh;
    width: 87.5%;
    left: 50%;
    margin-left: -43.75%;
  }
}
@media (min-width: 1024px) and (max-width: 1263px) {
  .onboarding-panel-size {
    height: 30vh;
    min-height: 292px;
    top: 30vh;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
  }
}
@media (min-width: 1264px) {
  .onboarding-panel-size {
    height: 30vh;
    min-height: 287px;
    top: 30vh;
    width: 55%;
    left: 50%;
    margin-left: -27.5%;
  }
}
@media (max-width: 320px) {
  .onboarding-title-regular-mobile {
    flex: 80%;
  }
}
@media (min-width: 321px) and (max-width: 600px) {
  .onboarding-title-regular-mobile {
    flex: 100%;
  }
  .number-step-box {
    width: 51px;
    height: 51px;
  }
}
/* Default adjust */
@media (min-width: 600px) {
  .panel-size-increased-on-specialties {
    height: 98vh;
    top: 1vh;
  }
  .panel-size-increased-on-keywords {
    height: 98vh;
    top: 1vh;
  }
}
/* Default adjust for all generic non-portable devices */
@media (min-width: 600px) and (min-height: 600px) {
  .panel-size-increased-on-specialties {
    height: 90vh;
    top: 5vh;
  }
  .panel-size-increased-on-keywords {
    height: 90vh;
    top: 5vh;
  }
}
/* Only tablets */
@media (min-width: 768px) and (min-height: 1024px) and (orientation:portrait) {
  .panel-size-increased-on-specialties {
    height: 90vh;
    top: 5vh;
  }
  .panel-size-increased-on-keywords {
    height: 50vh;
    top: 25vh;
  }
}
/* Odd desktops and tablets in landscape mode */
@media (min-width: 600px) and (min-height: 600px) and (orientation:landscape) {
  .panel-size-increased-on-specialties {
    height: 90vh;
    top: 5vh;
  }
  .panel-size-increased-on-keywords {
    height: 70vh;
    top: 15vh;
  }
}
/* Regular small desktops */
@media (min-width: 1024px) and (min-height: 720px) and (orientation:landscape) {
  .panel-size-increased-on-specialties {
    height: 90vh;
    top: 5vh;
  }
  .panel-size-increased-on-keywords {
    height: 70vh;
    top: 15vh;
  }
}
/* Large tablets in landscape mode or medium desktops and up */
@media (min-width: 1024px) and (min-height: 1024px) and (orientation:landscape) {
  .panel-size-increased-on-specialties {
    height: 70vh;
    top: 15vh;
  }
  .panel-size-increased-on-keywords {
    height: 50vh;
    top: 25vh;
  }
}
</style>

