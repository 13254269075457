import {
  doGetFeed,
  doPostIncrementShareCounter,
  doGetSortByTop,
  doGetMyFeed,
  doFinishOnboarding,
  getOnboarding
} from "@/api/feed";
import { isSuccessfulResponse } from "@/api/utilities";
import UtilitiesComponent from "@/components/common/UtilitiesComponent";

export default {
  namespaced: true,
  state: () => ({
    onboardingFinished: false,
    checkIfRequestFromOnboardingIsDone: false,
  }),
  mutations: {
    setOnboardingFinish(state) {
      state.onboardingFinished = true;
    },
    setCheckIfRequestFromOnboardingIsDone(state) {
      state.checkIfRequestFromOnboardingIsDone = true;
    },
  },
  actions: {
    getFeed() {
      return doGetFeed();
    },
    increaseShareCounter(context,submissionId) {
      return doPostIncrementShareCounter(submissionId);
    },
    sortByTop() {
      return doGetSortByTop();
    },
    getMyFeed() {
      return doGetMyFeed();
    },
    async finishOnboarding(context) {
      const response = await doFinishOnboarding();
      if(isSuccessfulResponse(response)) {
        context.commit("setOnboardingFinish");
      }
      return response;
    },
    async getOnboardingStatus(context) {
      const response = await getOnboarding();
      if(isSuccessfulResponse(response)) {
        if (response.data.onboarding === UtilitiesComponent.data().onboardingEnum.DONE ) {
          context.commit("setOnboardingFinish");
        }

        context.commit("setCheckIfRequestFromOnboardingIsDone");
      }
      return response;
    },
  },
  getters: {
    isOnboardingFinished: state => {
      return state.onboardingFinished;
    },
    isOnboardingRequestDone: state => {
      return state.checkIfRequestFromOnboardingIsDone;
    },
  }
}
