import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import store from '@/store/index';
import router from './router';
import './toasts';

import debounce from 'lodash.debounce';
Object.defineProperty(Vue.prototype, '$_debounce', { value: debounce });

import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import VueSocialAuth from 'vue-social-auth';
Vue.use(VueSocialAuth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_SOCIALITE_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_SOCIALITE_GOOGLE_REDIRECT_URL
    }
  }
});

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { expire: '7d' });

Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

router.beforeResolve(async (to, from, next) => {

  if (!store.getters["auth/currentUser"]?.email) {
    await store.dispatch('auth/getUser');
  }

  let user = store.getters["auth/currentUser"];

  if (user) {
    await store.dispatch('profile/getProfile');
  }

  if (user && ["/", "home"].includes(to.name)) {
    await store.dispatch("feed/getOnboardingStatus");
  }

  const pathsWithoutRedirection = ['login', 'register', 'home', 'article', 'password-reset', 'specialties', 'article-quiz'];
  const currentPathHasRedirection = !pathsWithoutRedirection.includes(to.name);
  const hasToRedirectToLogin = currentPathHasRedirection && !user;

  if (hasToRedirectToLogin) {
    next({ name: 'login' })
  } else {
    next()
  }
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "*.acapedia.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0,
  logErrors: true,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'local',
  // TODO: we are going to remove the 'v' prefix to tags
  release: `v${process.env.PACKAGE_VERSION}`,
});

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store
}).$mount('#app')
