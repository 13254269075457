<template>
  <div>
    <v-row
      :class="{'theme-selected': isSelected || forceSelection }"
      class="theme-chip align-center px-2 pt-2 no-gutters"
      @click.stop="toggleSpecialty"
    >
      <v-col
        cols="12"
        class="d-flex"
      >
        <div>
          <ph-check-square
            v-show="isSelected"
            weight="fill"
            size="24"
            class="clickable mr-3 secondary--text"
          />
          <ph-square
            v-show="!isSelected"
            weight="thin"
            size="24"
            class="clickable mr-3 grey--text text--lighten-6"
          />
        </div>
        <div class="heading text-l-large-semibold grey--text text--darken-4 text-truncate">
          {{ primaryTitle }}
          <span
            class="text rounded-lg text-capitalize theme-btn-text"
            :class="handleForSubspecialtiesAccordionButton"
            :disabled="subspecialties == undefined || subspecialties.length == 0"
            @click.stop="toggleOpenSubspecialties"
          >
            {{ subspecialtiesBtnText }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mt-0"
      justify="end"
    >
      <v-col
        v-if="showSubspecialties"
        cols="11"
        class="pt-1"
      >
        <SubSpecialtyComponent
          v-for="(subspec) in subspecialties"
          :id="subspec.id"
          :key="subspec.id"
          :title="subspec.title"
          :force-selection="subspec.forceSelection"
          @select-sub="$emit('select-sub', primaryId, subspec.id)"
          @deselect-sub="$emit('deselect-sub', primaryId, subspec.id)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UtilitiesComponent from '@/components/common/UtilitiesComponent';
import SubSpecialtyComponent from "@/components/specialties/SubSpecialtyComponent";
import { PhCheckSquare, PhSquare } from "phosphor-vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { SubSpecialtyComponent, UtilitiesComponent, PhCheckSquare, PhSquare },
  props: [ 'primaryId', 'primaryIndex', 'primaryTitle', 'subspecialties', 'forceSelection', 'currentSelectedsCount'],
  data: () => ({
    isSelected: false,
    showSubspecialties: false,
    numberUtilities: UtilitiesComponent.data().number
  }),
  computed: {
    subspecialtiesBtnText() {
      return this.subspecialties?.length ? `${this.subspecialties.length} Subspecialties` : "No Subspecialties";
    },
    handleForSubspecialtiesAccordionButton() {

      if (!this.subspecialties.length || this.showSubspecialties) {
        return 'text-m-large-regular grey--text text--darken-1';
      }

      return 'text-m-large-bold primary--text';
    }
  },
  watch: {
    forceSelection: function (newValue){
      this.isSelected = newValue;
    }
  },
  mounted(){
    this.isSelected = this.forceSelection;
  },
  methods: {
    toggleSpecialty() {
      this.isSelected = !this.isSelected;
      this.$emit(this.isSelected ? 'select':'deselect', this.primaryId);
    },
    toggleOpenSubspecialties() {
      this.showSubspecialties = !this.showSubspecialties;
    }
  }
}
</script>

<style scoped>
  .theme-chip {
    display: flex;
    border-radius: 4px;
    box-shadow: -5px -5px 30px white, 5px 5px 30px rgba(174, 174, 192, 0.4);
    margin: 8px 5px;
    background-color: var(--v-background-base);
    padding: 6px 12px;
    height: 81px;
  }
  .theme-chip span {
    width: auto;
  }
  .theme-chip > .d-flex {
    width: calc(80vw);
  }
  .theme-chip:hover {
    background-color: var(--v-secondary-hover-base);
    cursor: pointer;
  }
  .theme-chip.theme-selected {
    border: 1px var(--v-secondary-border-base) solid;
  }
  .theme-chip-title {
    color: #171725;
  }
  .theme-number-bold {
    text-align: center;
    color: #696974;
  }
  .theme-btn-text {
    display: flex;
    align-items: center;
    text-align: center;
  }
  i.v-icon {
    margin-top: -4px;
  }
</style>
