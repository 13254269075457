import { doGetSpecialties, doSaveSpecialtiesToUser, doGetSpecialtiesSelected } from "@/api/specialties";
import { isSuccessfulResponse } from "@/api/utilities";

export default {
  namespaced: true,
  state: () => ({
    selecteds: null
  }),
  mutations: {
    setSelected(state, data) {
      state.selecteds = data;
    }
  },
  actions: {
    getAll() {
      return doGetSpecialties();
    },
    saveSpecialtiesToUser(context, payload) {
      return doSaveSpecialtiesToUser(payload);
    },
    async getSpecialtiesSelected(context) {

      const response = await doGetSpecialtiesSelected();

      if(isSuccessfulResponse(response)) {
        context.commit('setSelected', response.data);
      }

      return response;
    },
    getSelected({ getters }) {
      return getters.currentSelecteds;
    }
  },
  getters: {
    currentSelecteds: state => {
      return state.selecteds;
    }
  }
}
