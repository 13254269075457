import axios from "axios";

const doGetArticle = async (articleId) => {
  return axios.get("/articles/view-article-details", {params:{id:articleId} } ).then(response => {
    return response.data;
  }).catch(error => {
    console.error("There was an error!", error);
    return error;
  });
}

const doPostUpVoteArticle = async (articleId) => {
  return axios.post("/articles/up-vote",  {id:articleId}).then(response => {
    return response.data;
  }).catch(error => {
    console.error("There was an error!", error);
    return error;
  });
}

const doPostDownVoteArticle = async (articleId) => {
  return axios.post("/articles/down-vote", {id:articleId}).then(response => {
    return response.data;
  }).catch(error => {
    console.error("There was an error!", error);
    return error;
  });
}

const doGetArticleQuiz = async (payload) => {
  return axios.get("/article/quiz", payload);
}

const doPostQuizAnswer = async (payload) => {
  return axios.post("/article/quiz/question/answer", payload);
}

export {
  doGetArticle,
  doGetArticleQuiz,
  doPostUpVoteArticle,
  doPostDownVoteArticle,
  doPostQuizAnswer,
}
