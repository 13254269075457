import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "HomeComponent" */ '@/components/HomeComponent')
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import(/* webpackChunkName: "ViewArticle" */ '@/components/article/ViewArticle')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "LoginComponent" */ '@/components/auth/LoginComponent')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "RegisterComponent" */ '@/components/auth/RegisterComponent')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "ProfileComponent" */ '@/components/profile/ProfileComponent')
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import(/* webpackChunkName: "EditProfileFieldsComponent" */ '@/components/profile/EditProfileFieldsComponent')
  },
  {
    path: '/password-reset/:email/:token(.*)',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "PasswordResetComponent" */ '@/components/password/PasswordResetComponent')
  },
  {
    path: '/article/:id/quiz',
    name: 'article-quiz',
    component: () => import(/* webpackChunkName: "ViewArticle" */ '@/components/article/ViewArticle')
  },
  {
    path: '/specialties',
    name: 'specialties',
    component: () => import(/* webpackChunkName: "SpecialtyListComponent" */ '@/components/specialties/SpecialtyListComponent')
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import(/* webpackChunkName: "KeywordListComponent" */ '@/components/keywords/KeywordListComponent')
  },
  {
    path: '/transcripts',
    name: 'transcripts',
    component: () => import(/* webpackChunkName: "TranscriptListComponent" */ '@/components/transcripts/TranscriptListComponent')
  },
  {
    path: '/transcripts/:id/results',
    name: 'transcripts-results',
    component: () => import(/* webpackChunkName: "TranscriptResultsComponent" */ '@/components/transcripts/TranscriptResultsComponent')
  },
  {
    path: '/my-plan',
    name: 'my-plan',
    component: () => import(/* webpackChunkName: "SubscriptionPlansComponent" */ '@/components/subscriptions/SubscriptionPlansComponent')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
