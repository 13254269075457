<template>
  <div class="keywords-container">
    <v-row class="sticky-header-container mx-3 mx-sm-0 mt-2 mb-0 align-center">
      <v-col class="col-auto pa-1">
        <ph-caret-left
          weight="light"
          size="24"
          class="clickable grey--text text--lighten-1"
          @click="goBackToWelcomeSteps"
        />
      </v-col>
      <v-col class="col-8 text-left pa-1">
        <h6
          class="text grey--text text--darken-1"
          :class="$vuetify.breakpoint.smAndUp ? 'text-xl-bold' : 'text-l-large-bold'"
        >
          Select interests
        </h6>
      </v-col>
      <v-col
        cols="12"
        class="px-2 pt-2 pt-sm-0"
      >
        <p class="text text-l-large-regular mb-0">
          Select any specific interests you may have to obtain better article recommendations.
        </p>
      </v-col>
      <v-col
        cols="12"
        class="px-2 pt-2 pt-sm-0"
      >
        <v-text-field
          v-model="search"
          class="keywords-search"
          solo
          hide-details
          placeholder="Search interest"
        >
          <template #prepend-inner>
            <ph-magnifying-glass
              weight="thin"
              size="24"
              class="grey--text text--lighten-2 mr-2"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="chips-container justify-center mx-3 mx-sm-0 mt-2 mb-15 mb-sm-7 pb-sm-0">
      <v-col
        v-if="loadingKeywords"
        cols="12"
      >
        <ProgressBarComponent
          :title="'Keywords'"
        />
      </v-col>
      <v-col
        cols="12"
        class="px-0 pt-0"
      >
        <v-row
          v-if="!loadingKeywords"
          class="max-height"
          dense
        >
          <v-col
            cols="12"
            class="max-height"
          >
            <div
              class="text-left mt-0 py-1 px-1"
            >
              <KeywordChipComponent
                v-for="keyword in keywordsToDisplay"
                :key="keyword+uniqueKeysHelper()"
                :keyword="keyword"
                :user-keywords="currentUserKeywords"
                :is-big-mode="true"
                @subscribe-keyword="handleSubscribeToKeyword"
                @unsubscribe-keyword="handleUnsubscribeToKeyword"
              />
            </div>
            <div
              v-if="search && keywordsToDisplay.length === 0"
              class="no-keywords-found-container"
            >
              <div class="row justify-center">
                <div class="col col-11 col-sm-6 col-md-6 text-center">
                  <SearchNoFoundIconComponent />
                  <p class="text text-m-large-regular grey--text text--darken-1 mt-2">
                    <span class="text text-m-large-bold">There’s no results matching your interests</span>.
                    <br v-if="$vuetify.breakpoint.width >= 375">Try searching using different words or just type another interest.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="!loadingKeywords"
      class="sticky-actions-container justify-center mx-3 mx-sm-0 mt-0 pb-sm-8 px-sm-8"
    >
      <v-card
        class="actions-component-sticky pt-2 px-2 mb-6 mb-sm-0"
        flat
      >
        <v-row
          justify="center"
          justify-sm="end"
          align="center"
        >
          <div class="col-12 col-sm-auto">
            <v-btn
              ripple
              depressed
              block
              height="44px"
              color="grey lighten-5"
              class="primary--text heading heading-s-small align-self-center unaltered-text-cap letter-spacing-normal shadow-light-button px-4 mr-sm-4"
              @click="finishKeywordsSelection"
            >
              Do it later
            </v-btn>
          </div>
          <div class="col-12 col-sm-auto">
            <v-btn
              :disabled="currentSelectedKeywordsCount === 0"
              ripple
              depressed
              block
              height="44px"
              color="primary"
              class="heading heading-s-small text-capitalize px-6"
              @click.stop="finishKeywordsSelection"
            >
              Continue
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ProgressBarComponent from '@/components/common/ProgressBarComponent';
import KeywordChipComponent from "@/components/common/KeywordChipComponent";
import SearchNoFoundIconComponent from "@/assets/SearchNoFoundIconComponent";
import { PhCaretLeft, PhMagnifyingGlass } from "phosphor-vue";
export default {
  components: { ProgressBarComponent, KeywordChipComponent, PhCaretLeft, PhMagnifyingGlass, SearchNoFoundIconComponent },
  data: () => ({
    search: null,
    allKeywords: null,
    filteredKeywords: null,
    loadingKeywords: true,
    keywordsToSubscribe: [],
    keywordsToUnsubscribe: [],
    unselectedKeywords: [],
    uniqueKeysHelper: uuidv4,
  }),
  computed: {
    ...mapGetters('keywords', ['currentUserKeywords']),
    ...mapGetters('auth', ['currentUser']),
    currentSelectedKeywordsCount() {
      return this.currentUserKeywords?.length || 0;
    },
    keywordsToDisplay() {

      if (this.search) {
        return this.filteredKeywords;
      }

      return [...this.currentUserKeywords, ...this.unselectedKeywords ];
    }
  },
  watch: {
    search: function () {

      const keywordsToFilter = [...this.currentUserKeywords, ...this.unselectedKeywords ];

      if (this.search !== null && this.search.trim() !== "") {
        let cleanSearch = this.search.trim().toLowerCase().replace(/\s+/g, " ");

        this.filteredKeywords = keywordsToFilter.filter( keyword => {
          return keyword.toLowerCase().includes(cleanSearch);
        });
      } else {
        this.filteredKeywords = keywordsToFilter;
      }
    }
  },
  async mounted() {

    if(this.currentUser) {
      await this.getUserKeywords();
      this.getAllKeywords().then( response => {
        this.allKeywords = response.data;
        this.refreshUnselectedKeywords();
        this.loadingKeywords = false;
      });
    }
  },
  methods: {
    ...mapActions('keywords', ['getUserKeywords','getAllKeywords', 'subscribeKeywordsToUser', 'unsubscribeKeywordsToUser']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    async handleSubscribeToKeyword(keyword) {

      if(!this.currentUser) return;

      if(this.currentUser) {

        this.keywordsToSubscribe.push(keyword);

        const response = await this.subscribeKeywordsToUser({ keywords: this.keywordsToSubscribe });

        if (response.data) {
          this.refreshUnselectedKeywords();
          this.keywordsToSubscribe = [];
        }

      }
    },
    async handleUnsubscribeToKeyword(keyword) {

      if(!this.currentUser) return;

      if(this.currentUser) {

        this.keywordsToUnsubscribe.push(keyword);

        const response = await this.unsubscribeKeywordsToUser({ keywords: this.keywordsToUnsubscribe })

        if (response.data) {
          this.refreshUnselectedKeywords();
          this.keywordsToUnsubscribe = [];
        }
      }
    },
    refreshUnselectedKeywords() {
      this.unselectedKeywords = this.allKeywords.filter( keyword => { return this.currentUserKeywords.indexOf(keyword) < 0; });
    },
    finishKeywordsSelection() {
      this.$emit("finish-keywords-selection");
    },
    goBackToWelcomeSteps() {
      this.$emit("show-again-welcome-steps");
    },
  },
}
</script>
<style scoped>
.actions-component-sticky {
  height: 52px;
  width: 100%;
  background-color: transparent !important;
}
.chips-container {
  height: 48%;
  position: absolute;
  width: 92%;
  left: 4%;
  z-index: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 320px) {
  .chips-container {
    padding-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .actions-component-sticky {
    position: fixed;
    bottom: 70px;
    width: 90%;
  }
  .actions-component-sticky > .row {
    flex-direction: column-reverse;
  }
  .chips-container {
    left: initial;
  }
}
@media (min-width: 600px) {
  .sticky-actions-container {
    position: absolute;
    bottom: 40px;
    right: 20px;
    width: 100%;
    left: 0;
    margin-bottom: -50px;
    background-color: var(--v-background-base);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .actions-component-sticky {
    padding: 0 !important;
  }
  .actions-component-sticky > .row {
    background-color: var(--v-background-base);
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .theme-chip {
    width: 45%;
  }

}
.keywords-container {
  width: 100%;
}
.keywords-search >>> .v-input__slot {
  background: var(--v-background-base) !important;
  box-shadow: inset -3px -3px 3px rgba(255, 255, 255, 0.7), inset 3px 3px 3px rgba(174, 174, 192, 0.2) !important;
  border-radius: 4px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--v-grey-lighten2) !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: var(--v-grey-darken1) !important;
}
.no-keywords-found-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.no-keywords-found-container svg {
  flex: 100%;
}
</style>
