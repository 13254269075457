<template>
  <div>
    <div
      v-show="getIfShowVeil"
      class="black-veil"
    />
    <WelcomeRegisteredUserComponent
      :current-route="$route.path"
      :current-user="currentUser"
    />
    <v-app-bar
      v-show="!getHideMenu"
      app
      color="white"
      flat
    >
      <div
        class="d-block mr-auto mx-lg-0 clickable"
        @click="menuAction(0)"
      >
        <LogoMenuComponent :ref-number="1" />
      </div>
      <v-spacer class="d-block" />
      <div
        v-show="currentUser"
        class="credit-box px-2 mr-2"
        :class="classNameForCreditBox"
      >
        <v-row no-gutters>
          <v-col class="col-12 d-flex align-items-center">
            <div class="credit-image mr-1">
              <UnawardedBadgeIconComponent
                v-if="hasToShowUnawardedBadge"
                :width="23.2"
                :height="25.6"
              />
              <ProcessedBadgeIconComponent
                v-else
                :width="23.2"
                :height="25.6"
              />
            </div>
            <div :class="classNameForCreditText">
              <div class="text text-l-small-bold line-height-1">
                {{ creditsToShow }}
              </div>
              <div class="text text-s-small-regular line-height-1">
                CME
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-avatar
        v-show="currentUser"
        class="clickable"
        rounded
        :color="'grey darken-1'"
        size="32"
        @click="goToProfile"
      >
        <span class="white--text text-h6">{{ shortAvatarText }}</span>
      </v-avatar>
      <div
        v-if="!currentUser"
        class="d-flex clickable"
        @click="goToLogin"
      >
        <v-icon class="primary--text mr-2">
          mdi-login
        </v-icon>
        <span class="primary--text">Login</span>
      </div>
      <v-menu
        v-if="currentUser"
        offset-y
        class="hidden-sm-and-down"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            plain
            v-bind="attrs"
            class="hidden-sm-and-down"
            v-on="on"
            @click="goToProfile"
          >
            {{ fullName }}
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
    <v-app-bar
      v-show="getHideMenu"
      app
      color="white"
      flat
    >
      <ph-caret-left
        class="clickable"
        weight="thin"
        size="24"
        color="var(--v-grey-lighten1)"
        @click="goBack"
      />
      <h6 class="heading heading-s-small title-bar mx-auto">
        {{ getTitle }}
      </h6>
    </v-app-bar>
    <v-main class="pt-15">
      <v-container class="layout-container">
        <v-row
          no-gutters
          justify-sm="center"
        >
          <v-col
            class="mb-4 px-6 px-sm-11 px-lg-0"
            cols="12"
            sm="12"
            lg="12"
          >
            <slot name="content" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
const FeedLayoutComponent = () => import(/* webpackChunkName: "FeedLayoutComponent" */ '@/components/feed/FeedLayoutComponent');
import WelcomeRegisteredUserComponent from "@/components/onboarding/WelcomeRegisteredUserComponent";
import LogoutIconComponent from "@/assets/LogoutIconComponent";
import LogoMenuComponent from "@/assets/LogoMenuComponent";
import ProcessedBadgeIconComponent from "@/assets/ProcessedBadgeIconComponent";
import UnawardedBadgeIconComponent from "@/assets/UnawardedBadgeIconComponent";
import { mapActions, mapGetters } from "vuex";
import { PhCaretLeft } from "phosphor-vue";
export default {
  name: 'Home',
  // eslint-disable-next-line vue/no-unused-components
  components: { FeedLayoutComponent, WelcomeRegisteredUserComponent, LogoutIconComponent, LogoMenuComponent, ProcessedBadgeIconComponent, UnawardedBadgeIconComponent, PhCaretLeft },
  data: () => ({
    drawer: null,
    menuItemOptions: [
      { id: 0, text: 'Home', icon: 'mdi-home', route: '/'},
      { id: 1, text: 'Transcripts', icon: 'mdi-book-open-variant', route: '/transcripts'},
      { id: 2, text: 'My plan', icon: 'mdi-cash', route: '/my-plan'},
      { id: 3, text: 'Change specialties', icon: 'mdi-compare-horizontal', route: 'specialties'},
      { id: 4, text: 'Change keywords', icon: 'mdi-compare-horizontal', route: 'keywords'},
    ],
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentProfile']),
    ...mapGetters('menu', ['getHideMenu', 'getTitle', 'getIfShowVeil']),
    shortAvatarText() {
      return  this.currentUser?.email?.charAt(0).toUpperCase();
    },
    fullName() {
      return this.currentProfile ? `${this.currentProfile.firstName} ${this.currentProfile.lastName}` : "N/A";
    },
    creditsToShow() {
      const totalUnawardedCredits = +(this.currentUser?.unawardedCredits?.total ?? 0);
      const totalAwardedCredits = +(this.currentUser?.awardedCredits?.total ?? 0);

      return totalUnawardedCredits > 0 ? totalUnawardedCredits.toFixed(1) : totalAwardedCredits.toFixed(1);
    },
    classNameForCreditText() {
      return this.currentUser?.unawardedCredits?.total > 0 ? 'credit-number-state-unawarded' : 'credit-number-state-awarded';
    },
    hasToShowUnawardedBadge() {
      return this.currentUser?.unawardedCredits?.total > 0;
    },
    classNameForCreditBox() {
      return this.currentUser?.unawardedCredits?.total > 0 ? 'credit-box-unawarded' : 'credit-box-awarded';
    }
  },
  async mounted() {
    this.currentRoute = this.$route.name;
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('profile', ['getProfile']),
    goToProfile() {
      this.$router.push({name: 'profile'});
    },
    goToLogin() {
      this.$router.push({name: 'login'});
    },
    menuAction(id) {
      this.$router.push(this.menuItemOptions[id].route);
    },
    goBack() {
      if (this.$route.name !== 'profile') {
        this.$router.back();
      } else {
        this.$router.push({ name: 'home' });
      }
    }
  },
}
</script>

<style scoped>
.layout-container {
  background-color: var(--v-background-base);
  height: 100% !important;
  padding: 0px !important;
}

.input-search {
  padding-top: 23px!important;
}

.clickable:hover {
  cursor: pointer;
}

.border-left-3 {
  border-left: 3px solid #1DA1F2;
}

.no-border-radius {
  border-radius: 0;
}

.title-bar {
  color: #616161;
  text-transform: capitalize;
}
.credit-box {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 34px;
}

.credit-box-unawarded {
  background-color: #FFF8E1;
}

.credit-box-awarded {
  background-color: #90A4AE;
}

.credit-image {
  line-height: 1;
}

div.credit-box div.credit-number-state-awarded {
  line-height: 1 !important;
  color: white !important;
}

div.credit-box div.credit-number-state-unawarded {
  line-height: 1 !important;
  color: #FB8C00 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.black-veil {
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: absolute;
  z-index: 4;
  opacity: 0.5;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 3 !important;
}
</style>
