import axios from "axios";

const doGetSpecialties = async () => {
  return axios.get("/specialties").then(response => {
    return response.data;
  }).catch(error => {
    console.error("There was an error!", error);
    return error;
  });
}

const doSaveSpecialtiesToUser = async (payload) => {
  return axios.post("/user/specialties", payload);
}

const doGetSpecialtiesSelected = async () => {
  return axios.get("/user/specialties");
}

export {
  doGetSpecialties,
  doSaveSpecialtiesToUser,
  doGetSpecialtiesSelected,
}
