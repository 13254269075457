import { doGetUserSubscription, doGetPaymentIntent, doPostConfirmSubscription, doPostConfirmDowngradeSubscription, doGetSubscriptionChangePreview } from "@/api/subscriptions";
import { isSuccessfulResponse } from "@/api/utilities";

export default {
  namespaced: true,
  actions: {
    getUserSubscription() {
      return doGetUserSubscription();
    },
    getPaymentIntent() {
      return doGetPaymentIntent();
    },
    postConfirmSubscription(context, payload) {
      return doPostConfirmSubscription(payload);
    },
    async postConfirmDowngradeSubscription(context, payload) {

      let response = await doPostConfirmDowngradeSubscription(payload);

      if(isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getSubscriptionChangePreview(context, payload) {

      let response = await doGetSubscriptionChangePreview(payload);

      if(isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    }
  }
}
