import { doGetTranscripts, doGetTranscriptsQuizResults } from "@/api/transcripts";

export default {
  namespaced: true,
  actions: {
    getTranscripts(context, payload) {
      return doGetTranscripts(payload);
    },
    getTranscriptsQuizResults(context, payload ) {
      return doGetTranscriptsQuizResults(payload);
    },
  },
}
