import Vue from 'vue';
import { pushToast } from "../toasts";

const isSuccessfulResponse = (response) => response?.status >= 200 && response?.status <= 299;

const toastHandler = function (promise, showToast = true) {

	var toast = null;

	return promise.then( response => {

		if(showToast) {
			toast = Vue.toasted.global.showSuccess({message: response.data.message});
			pushToast(toast);
		}

		return response;

	}).catch( error => {

		const customErrorMsg = error.response?.data;

		if(showToast) {
			toast = Vue.toasted.global.showError({message: customErrorMsg ? customErrorMsg.message : error.message});
			pushToast(toast);
		}

		if (customErrorMsg) {
			return error.response;
		}

		return error;
	});

}

export {
	isSuccessfulResponse,
	toastHandler
}
