<template>
  <div
    class="d-flex theme-sub-chip align-center"
    :class="classSelection"
  >
    <div
      class="d-flex"
      @click.stop="selectSubSpecialty"
    >
      <div class="d-flex">
        <ph-check-square
          v-show="isSelected"
          weight="fill"
          size="24"
          class="clickable mr-3 secondary--text"
        />
        <ph-square
          v-show="!isSelected"
          weight="thin"
          size="24"
          class="clickable mr-3 grey--text text--lighten-6"
        />
      </div>
      <h6
        class="heading text-l-large-semibold grey--text text--darken-3 text-truncate"
      >
        {{ title }}
      </h6>
    </div>
  </div>
</template>

<script>
import UtilitiesComponent from '@/components/common/UtilitiesComponent';
import { PhCheckSquare, PhSquare } from "phosphor-vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { UtilitiesComponent, PhCheckSquare, PhSquare },
  props: ['id', 'title', 'forceSelection'],
  data: () => ({
    isSelected: false,
    numberUtilities: UtilitiesComponent.data().number
  }),
  computed: {
    classSelection() {
      return this.isSelected ? 'theme-selected': '';
    },
  },
  watch: {
   forceSelection: function (newValue) {
    this.isSelected = newValue;
   }
  },
  mounted() {
    this.isSelected = this.forceSelection;
  },
  methods: {
    selectSubSpecialty() {
      this.isSelected = !this.isSelected;
      this.$emit((this.isSelected ? "select-sub":"deselect-sub"), this.id);
    },
  }
}
</script>

<style scoped>
.theme-sub-chip {
  display: flex;
  border-radius: 4px;
  box-shadow: -5px -5px 30px white, 5px 5px 30px rgba(174, 174, 192, 0.4);
  margin: 5px;
  background-color: var(--v-background-base);
  padding: 8px 16px;
  justify-content: space-between;
  height: 40px;
}
.theme-sub-chip span {
  width: auto;
}
.theme-sub-chip > .d-flex {
  width: 100%;
}
.theme-sub-chip:hover {
  background-color: var(--v-secondary-hover-base);
  cursor: pointer;
}
.theme-sub-chip.theme-selected {
  border: 1px var(--v-secondary-border-base) solid;
}
.theme-number-bold {
  text-align: center;
  color: #696974;
}
.theme-number-light {
  color: #44444F;
}
i.v-icon {
  margin-top: -4px;
}
</style>
