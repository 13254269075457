<template>
  <div
    :class="{ 'diff-position-images': sizeImages.width === SIZE_FOR_MOBILE && currentStepIndex !== enumSteps.FIFTH, 
              'diff-position-images-se': sizeImages.width === SIZE_FOR_SE_MOBILE && currentStepIndex !== enumSteps.FIFTH }"
  >
    <FirstStepComponent
      v-show="currentStepIndex === enumSteps.FIRST"
      :size="sizeImages"
    />
    <SecondStepComponent
      v-show="currentStepIndex === enumSteps.SECOND"
      :size="sizeImages"
    />
    <ThirdStepComponent
      v-show="currentStepIndex === enumSteps.THIRD"
      :size="sizeImages"
    />
    <FourthStepComponent
      v-show="currentStepIndex === enumSteps.FOURTH"
      :size="sizeImages"
    />
    <FifthStepComponent
      v-show="currentStepIndex === enumSteps.FIFTH"
      :class="{ 'diff-position-images-last-image': $vuetify.breakpoint.xsOnly }"
      :size="sizeImages"
    />
    <EightStepComponent
      v-show="currentStepIndex === enumSteps.EIGHT"
      :size="sizeImages"
    />
  </div>
</template>
<script>
  export default {
    components: { 
      FirstStepComponent: () => import('./FirstStepComponent.vue'), 
      SecondStepComponent: () => import ('./SecondStepComponent.vue') , 
      ThirdStepComponent: () => import ('./ThirdStepComponent.vue') , 
      FourthStepComponent: () => import ('./FourthStepComponent.vue') , 
      FifthStepComponent: () => import ('./FifthStepComponent.vue'), 
      EightStepComponent: () => import ('./EightStepComponent.vue') 
    },
    props: ["currentStepIndex"],
    data: () => ({
      enumSteps: { FIRST: 0, SECOND: 1, THIRD: 2, FOURTH: 3, FIFTH: 4, EIGHT: 7 },
      SIZE_FOR_MOBILE: 414,
      SIZE_FOR_SE_MOBILE: 256,
      SIZE_FOR_TABLETS_AND_UP: 188
    }),
    computed: {
      sizeImages() {
        //Values for only one image that its not the same viewbox as the others
        if(this.currentStepIndex === this.enumSteps.FIFTH) {
          if (this.$vuetify.breakpoint.xsOnly && this.$vuetify.breakpoint.width > 320) {
            return { width: 569, height: 481 };
          }

          if(this.$vuetify.breakpoint.width <= 320) {
            return { width: 409, height: 346 };
          }

          return { width: 245, height: 207 };
        }

        if (this.$vuetify.breakpoint.xsOnly && this.$vuetify.breakpoint.width > 320) {
          return { width: this.SIZE_FOR_MOBILE, height: this.SIZE_FOR_MOBILE };
        }

        if(this.$vuetify.breakpoint.width <= 320) {
          return { width: this.SIZE_FOR_SE_MOBILE, height: this.SIZE_FOR_SE_MOBILE };
        }

        return { width: this.SIZE_FOR_TABLETS_AND_UP, height: this.SIZE_FOR_TABLETS_AND_UP };
      },
    }
  }
</script>

<style scoped>
.diff-position-images {
  margin-left: -165px;
  margin-top: -55px;
}
.diff-position-images-se {
  margin-left: -95px;
  margin-top: -85px;
}
.diff-position-images-last-image {
  margin-left: -20px;
}
</style>