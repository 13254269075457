export default {
  primary: {
    base: "#263684",
  },
  "primary-surface": {
    base: "#EDEDF7",
  },
  "primary-focus": {
    base: "#C2C6D9"
  },
  secondary: {
    base: "#76AAFF",
  },
  "secondary-surface": {
    base: "#F0F3FF",
  },
  "secondary-hover": {
    base: "#6B92D2",
  },
  "secondary-border": {
    base: "#B8D2FF",
  },
  accent: {
    base: "#FFAB18",
  },
  error: {
    base: "#E00000",
  },
  "error-surface": {
    base: "#FFF8F8",
  },
  warning: {
    base: "#EA780E",
  },
  "warning-surface": {
    base: "#FFF2E7",
  },
  info: {
    base: "#0B5CD7",
  },
  success: {
    base: "#13B577",
  },
  "success-surface": {
    base: "#F6FEFB",
  },
  background: {
    base: "#F0F0F3",
  },
  card: {
    base: "#F5F5F5"
  },
  grey: {
    base: "#FFFFFF",
    lighten6: "#B9B9B9",
    lighten5: "#F5F5F5",
    lighten4: "#EDEDED",
    lighten3: "#E0E0E0",
    lighten2: "#C2C2C2",
    lighten1: "#9E9E9E",
    darken1: "#757575",
    darken2: "#616161",
    darken3: "#424242",
    darken4: "#0A0A0A",
  },
  nightgradient: {
    base: "#263684",
    darken4: "#060F3D",
  },
  coldgradient: {
    base: "#8BB8FF",
    darken4: "#4C70DF",
  },
  hotgradient: {
    base: "#FFB018",
    darken4: "#FF8718",
    darken3: "#FD6B51",
  },
}
