import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './../theme';

Vue.use(Vuetify);


let customTheme = {
  theme: {
    themes: { light },
    options: { customProperties: true },
  }
};

export default new Vuetify(customTheme);
