import axios from "axios";
import { toastHandler } from "@/api/utilities";

const getBaseInstance = () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL.replace('/api','')
  });
}

const getCSRFToken = async () => {
  const baseInstance = getBaseInstance();

  await baseInstance.get("/sanctum/csrf-cookie", { headers: {
    'X-Requested-With': 'XMLHttpRequest'},
    withCredentials: true,});
}

const doLogin = async (form) => {

  let result = null;
  await getCSRFToken();

  const baseInstance = getBaseInstance();

  result = await baseInstance.post("/login", form);

  return result;
}

const getUser = () => {
  return axios.get("/user");
}

const doLogout = async () => {

  let result = null;
  await getCSRFToken();

  result = await axios.post("/logout");

  return result;
}

const doCheckPassword = async (params) => {
  return axios.post("/check-password", params);
};

const doChangePassword = async (params) => {
  return axios.post("/confirm-password", params);
}

const doSendPasswordReset = async (params) => {
  return axios.post("/send-password-reset", params);
}

const doCheckEmailExitence = async (params) => {
  return toastHandler(axios.post("/email-exists", params), true);
}

const doCheckEmailForResetPassword = async (params) => {
  return axios.post("/email-exists-reset-password", params);
}

const doVerifyResetPasswordToken = async (params) => {
  return axios.post("/password-reset/token", params)
  .catch(error => {
    console.error("There was an error!", error);
  });
}

const doSendConfirmationCode = async (params) => {
  return axios.post("/send-confirmation-code", params);
}

const doVerifyPhoneNumber = async (params) => {
  return axios.post("/verify-phone-number", params);
}

const doRegister = async (params) => {
  return axios.post("/register", params);
}

const doDeleteAccount = async (params) => {
  return axios.delete('/user', params);
}

const doSendDeleteCode = async () => {
  return axios.post('/send-delete-code');
}

const doConfirmDeleteCode = async (params) => {
  return axios.post("/verify-delete-code", params);
}

const doSendCodeToPhone = async () => {
  return axios.post('/user/profile/phone/code');
}

const doVerifyPhoneCode = async (params) => {
  return axios.post("/user/profile/phone/code/verify", params);
}

const doSendCodeToEmail = async () => {
  return axios.post('/user/email/code');
}

const doVerifyEmailCode = async (params) => {
  return axios.post("/user/email/code/verify", params);
}

export {
  doLogin,
  getUser,
  doLogout,
  doCheckPassword,
  doChangePassword,
  doSendPasswordReset,
  doCheckEmailExitence,
  doCheckEmailForResetPassword,
  getCSRFToken,
  doVerifyResetPasswordToken,
  doSendConfirmationCode,
  doVerifyPhoneNumber,
  doRegister,
  doDeleteAccount,
  doSendDeleteCode,
  doConfirmDeleteCode,
  doSendCodeToPhone,
  doVerifyPhoneCode,
  doSendCodeToEmail,
  doVerifyEmailCode,
}
