import { doLogin, getUser, doLogout, doCheckPassword, doChangePassword, doCheckEmailExitence, doCheckEmailForResetPassword,
  doSendPasswordReset, doVerifyResetPasswordToken, doSendConfirmationCode, doVerifyPhoneNumber, doRegister,
  doSendDeleteCode, doDeleteAccount, doConfirmDeleteCode, doSendCodeToPhone, doVerifyPhoneCode, doSendCodeToEmail, doVerifyEmailCode } from "@/api/auth";
import { isSuccessfulResponse } from "@/api/utilities";

export default {
  namespaced: true,
  state: () => ({
    user: null
  }),
  mutations: {
    setUser(state, data) {
      state.user = data;
    }
  },
  actions: {
    async login(context, params) {

      const response = await doLogin(params);

      if(isSuccessfulResponse(response)) {
        context.commit('setUser', response.data);
      }
    },
    async getUser(context) {
      try {
        const response = await getUser();

        if(isSuccessfulResponse(response)) {
          context.commit('setUser', response.data);
        }
      } catch (error) {
        context.commit('setUser', null);
      }
    },
    async logout(context) {

      const response = await doLogout();

      if(isSuccessfulResponse(response)) {
        context.commit('setUser', null);
      }
    },
    checkPassword(context, payload) {
      return doCheckPassword(payload);
    },
    changePassword(context, payload) {
      return doChangePassword(payload);
    },
    emailExists(context, payload) {
      return doCheckEmailExitence(payload);
    },
    emailExistsForResetPassword(context, payload) {
      return doCheckEmailForResetPassword(payload);
    },
    sendPasswordReset(contenxt, payload) {
      return doSendPasswordReset(payload);
    },
    verifyResetPasswordToken(context, payload) {
      return doVerifyResetPasswordToken(payload);
    },
    sendConfirmationCode(context, payload){
      return doSendConfirmationCode(payload);
    },
    verifyPhoneNumber(context, payload){
      return doVerifyPhoneNumber(payload);
    },
    async register(context, params) {

      const response = await doRegister(params);

      if(isSuccessfulResponse(response)) {
        context.commit('setUser', response.data);
      }
    },
    async deleteAccount(context, payload) {
      const response = await doDeleteAccount(payload);

      if(isSuccessfulResponse(response)) {
        context.commit('setUser', null);
      }
      return response;
    },
    sendDeleteCode(context, payload) {
      return doSendDeleteCode(payload);
    },
    confirmDeleteCode(context, payload) {
      return doConfirmDeleteCode(payload);
    },
    sendCodeToPhone() {
      return doSendCodeToPhone();
    },
    verifyPhoneCode(context, payload){
      return doVerifyPhoneCode(payload);
    },
    sendCodeToEmail() {
      return doSendCodeToEmail();
    },
    verifyEmailCode(context, payload){
      return doVerifyEmailCode(payload);
    },
  },
  getters: {
    currentUser: state => {
      return state.user;
    }
  }
}
