export default {
  namespaced: true,
  state: () => ({
    hideMenu: false,
    title: null,
    veil: false
  }),
  mutations: {
    setHideMenu(state, data) {
      state.hideMenu = data;
    },
    setTitle(state, data) {
      state.title = data;
    },
    setVeil(state, data) {
      state.veil = data;
    }
  },
  actions: {
    hideTopMenu(context, payload) {
      context.commit('setHideMenu', payload);
    },
    setTopMenuTitle(context, payload) {
      context.commit('setTitle', payload);
    },
    setGeneralVeil(context, payload) {
      context.commit('setVeil', payload);
    },
  },
  getters: {
    getHideMenu: state => {
      return state.hideMenu;
    },
    getTitle: state => {
      return state.title;
    },
    getIfShowVeil: state => {
      return state.veil;
    }
  }
}
