<template>
  <div class="specialties-container">
    <v-row class="sticky-header-container mx-3 mx-sm-0 mt-2 mb-0 align-center">
      <v-col class="col-auto pa-1">
        <ph-caret-left
          weight="light"
          size="24"
          class="clickable grey--text text--lighten-1"
          @click="goBackToWelcomeSteps"
        />
      </v-col>
      <v-col class="col-8 text-left pa-1">
        <h6
          class="text grey--text text--darken-1"
          :class="$vuetify.breakpoint.smAndUp ? 'text-xl-bold' : 'text-l-large-bold'"
        >
          Select specialties
        </h6>
      </v-col>
      <v-col
        cols="12"
        class="px-2 pt-2 pt-sm-0"
      >
        <p class="text text-l-large-regular mb-0">
          Select specialties and subspecialties that interest you. Start with your area of medical practice.
        </p>
      </v-col>
      <v-col
        cols="12"
        class="px-2 pt-2 pt-sm-0"
      >
        <v-text-field
          v-model="search"
          class="specialties-search"
          solo
          hide-details
          placeholder="Search specialty"
        >
          <template #prepend-inner>
            <ph-magnifying-glass
              weight="thin"
              size="24"
              class="grey--text text--lighten-2 mr-2"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="chips-container justify-center mx-3 mx-sm-0 mt-0 mb-7">
      <v-col
        v-if="loadingSpecialties"
        cols="12"
      >
        <ProgressBarComponent
          :title="'Specialties'"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-0 pt-0"
      >
        <SpecialtyChipComponent
          v-for="(spec,i) in specsPartialLeft"
          v-show="spec.show"
          :key="spec.id"
          class="py-1"
          :primary-index="i"
          :primary-title="spec.primary"
          :primary-id="spec.id"
          :subspecialties="spec.subspecialties"
          :force-selection="spec.forceSelection"
          :current-selecteds-count="currentSelectedSpecialtiesCount"
          @select="selectSpecialty"
          @deselect="deselectSpecialty"
          @select-sub="selectSubSpecialty"
          @deselect-sub="deselectSubSpecialty"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-0 pt-0"
      >
        <SpecialtyChipComponent
          v-for="(spec,i) in specsPartialRight"
          v-show="spec.show"
          :key="spec.id"
          class="py-1"
          :primary-index="(i+specsPartialLeft.length)"
          :primary-title="spec.primary"
          :primary-id="spec.id"
          :subspecialties="spec.subspecialties"
          :force-selection="spec.forceSelection"
          :current-selecteds-count="currentSelectedSpecialtiesCount"
          @select="selectSpecialty"
          @deselect="deselectSpecialty"
          @select-sub="selectSubSpecialty"
          @deselect-sub="deselectSubSpecialty"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!loadingSpecialties"
      class="sticky-actions-container justify-center mx-3 mx-sm-0 mt-0"
    >
      <v-card
        class="actions-component-sticky pt-2 px-2 mb-6 mb-sm-7 mr-sm-3 mr-md-7"
        flat
      >
        <v-row
          justify="center"
          justify-sm="end"
          align="center"
        >
          <div class="col col-sm-auto">
            <v-btn
              :disabled="currentSelectedSpecialtiesCount === 0"
              height="44px"
              ripple
              depressed
              block
              color="primary"
              class="heading heading-s-small text-capitalize px-6"
              @click.stop="submitSpecialties"
            >
              Continue
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpecialtyChipComponent from '@/components/specialties/SpecialtyChipComponent';
import ProgressBarComponent from '@/components/common/ProgressBarComponent';
import { PhCaretLeft, PhMagnifyingGlass } from "phosphor-vue";
export default {
  components: { SpecialtyChipComponent, ProgressBarComponent, PhCaretLeft, PhMagnifyingGlass },
  data: () => ({
    search: null,
    specialties: null,
    loadingSpecialties: true,
  }),
  computed: {
    ...mapGetters('specialties', ['currentSelecteds']),
    ...mapGetters('auth', ['currentUser']),
    currentSelectedSpecialtiesCount() {
      return this.specialties?.filter( spec => spec.forceSelection === true).length;
    },
    specsPartialLeft() {
      return this.specialties?.slice(0, Math.floor(this.specialties.length / 2)) ?? [];
    },
    specsPartialRight() {
      return this.specialties?.slice(Math.floor(this.specialties.length/2), this.specialties.length) ?? [];
    }
  },
  watch: {
    search: function () {
      if (this.search !== null && this.search.trim() !== "") {
        let cleanSearch = this.search.trim().toLowerCase().replace(/\s+/g, " ");

        this.specialties = this.specialties.map( sel => {

          if( sel.primary.toLowerCase().includes(cleanSearch) ) {
            sel.show = true;
          } else {
            sel.show = false;
          }

          sel.subspecialties = sel.subspecialties?.map( subpecialty => {
            if (subpecialty.title?.toLowerCase().includes(cleanSearch)) {
              sel.show = true;
            }

            return subpecialty;
          }) || [];

          return sel;
        });
      } else {
        this.specialties = this.specialties.map( sel => {
          sel.show = true;
          return sel;
        });
      }
    }
  },
  async mounted() {

    if(this.currentUser) {
      await this.getSpecialtiesSelected();
      await this.loadSpecialties();
    } else {
      const isGuess = true;
      await this.loadSpecialties(isGuess);
    }

    this.loadingSpecialties = this.specialties.length == 0;
  },
  methods: {
    ...mapActions('specialties', ['getAll', 'getSpecialtiesSelected', 'saveSpecialtiesToUser']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    selectSpecialty(id) {
      this.specialties = this.specialties.map( sel => sel.id === id ? { ...sel, forceSelection: true } : sel );
    },
    deselectSpecialty(id) {
      this.specialties = this.specialties.map( sel => sel.id === id ? { ...sel, forceSelection: false } : sel );

      this.specialties = this.specialties.map( sel => {
        if(sel.id === id) {
          sel.subspecialties = sel.subspecialties?.map( sub => { 
            return { ...sub, forceSelection: false };
          });
        }
        return sel;
      });
    },
    selectSubSpecialty(primaryId, subId) {

      let primarySpecialty = this.specialties.find( sel => sel.id === primaryId);

      this.specialties = this.specialties.map( sel => {
        if (sel.id === primarySpecialty.id)  { 
          sel = { ...sel, forceSelection: true };
        }

        sel.subspecialties = sel.subspecialties?.map( subpecialty => subpecialty.id === subId ? { ...subpecialty, forceSelection: true } : subpecialty ) || [];

        return sel;
      });

    },
    deselectSubSpecialty(primaryId, subId) {
      this.specialties = this.specialties.map( sel => {
        sel.subspecialties = sel.subspecialties?.map( subpecialty => subpecialty.id === subId ? { ...subpecialty, forceSelection: false } : subpecialty ) || [];

        return sel;
      });
    },
    submitSpecialties() {

      let allSpecialtiesIds = []; let allSubspecialtiesIds = [];
      this.specialties.forEach(specialty => {
        if(specialty.forceSelection) {

          allSpecialtiesIds.push(specialty.id);

          if (specialty.subspecialties) {
            allSubspecialtiesIds = allSubspecialtiesIds.concat(specialty.subspecialties.filter( sub => sub.forceSelection ).map( sub => sub.id ));
          }
        }
      });

      this.saveSpecialtiesToUser({ specialties: allSpecialtiesIds, subspecialties: allSubspecialtiesIds }).then( response => {
        this.$toasted.global.showSuccess({message: response.data.message});
        this.$emit("finish-specialties-selection");
      }).catch( error => {
        let errorMessage = "Unexpected error";
        
        if (error.response.data.errors ) {
          errorMessage = error.response.data.errors.specialties[0];
        }

        if (error.response.data) {
          errorMessage = error.response.data.message;
        }

        this.$toasted.global.showError({message: errorMessage});
      });
    },
    clearSpecialties() {
      this.specialties.map( specialty => {
        specialty.forceSelection = false;
        specialty.subspecialties = specialty.subspecialties?.map( subspec => { return { ...subspec, forceSelection: false }});
      });
    },
    async loadSpecialties(isGuess) {
      this.specialties = await this.getAll().then( response => {

        let loadedSpecialties = response.specialties.map( (spec, index) => {
          spec.show = true;
          spec.forceSelection = isGuess ? false : !!this.currentSelecteds?.specialties?.find(initialSpecialty => initialSpecialty.id === spec.id);
          spec.originalPosition = index;

          spec.subspecialties = spec.subspecialties?.map( subspecialty => {
            subspecialty.forceSelection = isGuess ? false : !!this.currentSelecteds?.specialties?.find(initialSpecialty => initialSpecialty.id === subspecialty.id);
            return subspecialty;
          });

          return spec;
        });

        return loadedSpecialties;

      }).catch( error => {
        this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Unexpected error'});
      });
    },
    goBackToWelcomeSteps() {
      this.$emit("show-again-welcome-steps");
    }
  },
}
</script>
<style scoped>
.actions-component-sticky {
  height: 52px;
  width: 100%;
  background-color: transparent !important;
}
.chips-container {
  height: 70%;
  position: absolute;
  width: 92%;
  left: 4%;
  z-index: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 600px) {
  .actions-component-sticky {
    position: fixed;
    bottom: 0px;
    width: 90%;
  }
  .chips-container {
    padding-bottom: 200px !important;
  }
}
@media (min-width: 600px) {
  .sticky-actions-container {
    position: absolute;
    bottom: 40px;
    right: 20px;
    width: 100%;
    left: 0;
    margin-bottom: -50px;
    background-color: var(--v-background-base);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .actions-component-sticky {
    padding: 0 !important;
  }
  .actions-component-sticky > .row {
    background-color: var(--v-background-base);
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .theme-chip {
    width: 45%;
  }
  .chips-container {
    height: 60%;
  }
}
.specialties-container {
  width: 100%;
}
.specialties-search >>> .v-input__slot {
  background: var(--v-background-base) !important;
  box-shadow: inset -3px -3px 3px rgba(255, 255, 255, 0.7), inset 3px 3px 3px rgba(174, 174, 192, 0.2) !important;
  border-radius: 4px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--v-grey-lighten2) !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: var(--v-grey-darken1) !important;
}
</style>
