import { doGetUserKeywords, doGetAllKeywords, doGetTopKeywords, doSubscribeKeywordsToUser, doUnsubscribeKeywordsToUser } from "@/api/keywords";
import { isSuccessfulResponse } from "@/api/utilities";

export default {
  namespaced: true,
  state: () => ({
    userKeywords: null,
  }),
  mutations: {
    setUserKeywords(state, data) {
      state.userKeywords = data;
    }
  },
  actions: {
    async getUserKeywords(context) {

      const response = await doGetUserKeywords();

      if(isSuccessfulResponse(response)) {
        context.commit('setUserKeywords', response.data);
      }

      return response;
    },
    getAllKeywords() {
      return doGetAllKeywords();
    },
    getTopKeywords() {
      return doGetTopKeywords();
    },
    async subscribeKeywordsToUser(context, payload) {

      const response = await doSubscribeKeywordsToUser(payload);

      if(isSuccessfulResponse(response)) {
        await context.dispatch('getUserKeywords');
      }

      return response;
    },
    async unsubscribeKeywordsToUser(context, payload) {

      const response = await doUnsubscribeKeywordsToUser(payload);

      if(isSuccessfulResponse(response)) {
        await context.dispatch('getUserKeywords');
      }

      return response;
    },
  },
  getters: {
    currentUserKeywords: state => {
      return state.userKeywords;
    }
  }
}
