<template>
  <div
    class="keyword-chip "
    :class="listMode ? 'd-block':'d-inline'"
    @click="toggleKeyword(keyword)"
  >
    <v-chip
      v-if="!isPlusButton"
      label
      :text-color="isKeywordSelected ? 'primary' : 'grey darken-3'"
      :outlined="isKeywordSelected"
      class="keyword-chip-item text mb-4 clickable"
      :class="{ 'keyword-chip-item-big' : isBigMode, 'primary-surface' : isKeywordSelected, 'white' : !isKeywordSelected, 'text-l-small-regular' : !isBigMode, 'text-l-large-regular' : isBigMode }"
    >
      {{ keyword }}
      <ph-x
        v-if="isKeywordSelected"
        class="clickable ml-2"
        weight="thin"
        size="18"
        color="var(--v-primary-base)"
      />
      <ph-plus
        v-else
        class="clickable ml-2"
        weight="thin"
        size="18"
        color="var(--v-grey-darken3)"
      />
    </v-chip>
    <v-chip
      v-if="isPlusButton"
      label
      color="white"
      text-color="grey darken-3"
      class="keyword-chip-item text text-l-small-regular mb-2 clickable"
      @click="showMoreInterests"
    >
      +{{ keyword }} interests
    </v-chip>
  </div>
</template>

<script>
import { PhX, PhPlus, PhWarningOctagon } from "phosphor-vue";
export default {
  components: { PhX, PhPlus },
  props: ['keyword', 'userKeywords', 'isPlusButton', "listMode", "isBigMode"],
  computed: {
    getKeywordIconStatus() {
      return this.findIfKeywordIsSelected() ? 'ph-x-thin primary--text' :  'ph-plus-thin grey--text text--darken-3';
    },
    isKeywordSelected() {
      return this.findIfKeywordIsSelected();
    }
  },
  methods: {
    toggleKeyword(keyword) {
      if(!this.userKeywords || !this.findIfKeywordIsSelected()) {
        this.$emit('subscribe-keyword', keyword);
      } else {
        this.$emit('unsubscribe-keyword', keyword);
      }
    },
    findIfKeywordIsSelected() {
      return this.userKeywords?.findIndex( k => k === this.keyword) >= 0;
    },
    showMoreInterests() {
      this.$emit('click-plus-remainig');
    }
  }
}
</script>

<style>
.keyword-chip-item {
  height: 36px !important;
}
.keyword-chip-item-big {
  height: 40px !important;
}
.keyword-chip {
  padding-right: 10px;
}
.keyword-chip .keyword-chip-item.v-chip.primary-surface {
  outline:  var(--v-primary-base) solid 1px;
  border-color: var(--v-primary-base);
}
.keyword-chip .v-chip.v-chip--outlined.v-chip.v-chip {
  background: var(--v-primary-surface-base) !important;
}
</style>
