<template>
  <v-app v-if="finishInitLoad">
    <LayoutComponent v-if="$route.name !== 'login' && $route.name !== 'register' && $route.name !== 'password-reset'">
      <template #content>
        <router-view />
      </template>
    </LayoutComponent>
    <router-view v-else />
  </v-app>
</template>

<script>
import LayoutComponent from "@/components/LayoutComponent";
export default {
  name: 'App',
  components: { LayoutComponent },
  data: () => ({
    finishInitLoad: false,
  }),
  async mounted() {
    this.finishInitLoad = true;
  }
};
</script>
<style lang="scss">
  @import "./sass/main.scss";
</style>
