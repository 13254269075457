import Vue from 'vue'
import Vuex from 'vuex'
import feed from "@/store/modules/feed";
import article from "@/store/modules/article";
import auth from "@/store/modules/auth";
import specialties from "@/store/modules/specialties";
import issue from "@/store/modules/issue";
import keywords from "@/store/modules/keywords";
import profile from "@/store/modules/profile";
import transcripts from "@/store/modules/transcripts";
import subscriptions from "@/store/modules/subscriptions";
import menu from "@/store/modules/menu";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  mutations: {},
  modules:{
    feed,
    article,
    auth,
    specialties,
    issue,
    keywords,
    profile,
    transcripts,
    subscriptions,
    menu,
  }
});
