import axios from "axios";

const doGetTranscripts = async (payload) => {
  return axios.get('/transcripts', payload);
}

const doGetTranscriptsQuizResults = async (payload) => {
  return axios.get('/transcripts/quiz/results', payload);
}

export {
  doGetTranscripts,
  doGetTranscriptsQuizResults,
}
