import { doPostReportQuiz } from "@/api/issue";

export default {
  namespaced: true,
  actions: {
    postReportQuiz(context,payload) {
      return doPostReportQuiz(payload);
    }
  }
}
