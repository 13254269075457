import axios from "axios";

const doGetProfileDetails = async () => {
  return axios.get("/user/profile");
}

const doUpdateEmail = async (payload) => {
  return axios.post("/user/profile/update-email", payload);
}

const doGetUserPaymentMethod = async () => {
  return axios.get("/user/billing/payment-method");
}

const doUpdateBillingAddress = async (payload) => {
  return axios.post("/user/billing/address/update", payload);
}

const doDeletePaymentMethod = async () => {
  return axios.delete("/user/billing/payment-method");
}

const doPostPaymentMethod = async (payload) => {
  return axios.post("/user/billing/payment-method", payload);
}

const doGetPaymentHistory = async () => {
  return axios.get("/user/billing/payment/history");
}

const doGetPaymentReceiptPdf = async (payload) => {
  return axios.get("/user/billing/payment/history/receipt/pdf", payload);
}

const doUpdateProfileNames = async (payload) => {
  return axios.post("/user/profile/update-names", payload);
}

export {
  doGetProfileDetails,
  doUpdateEmail,
  doGetUserPaymentMethod,
  doUpdateBillingAddress,
  doDeletePaymentMethod,
  doPostPaymentMethod,
  doGetPaymentHistory,
  doGetPaymentReceiptPdf,
  doUpdateProfileNames,
}
